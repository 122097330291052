const queryString = require("query-string");

const geoParams = {
  concelho: null,
  regiao: null,
  agentes: null,
  arquitecto: null,
  empreiteiro: null,
  freguesia__concelho: null,
  freguesia__concelho__regiao: null,
  disponibilidade: null,
};

const processGeoserverUrl = (params) => {
  try {
    geoParams.agentes = params["agentes"];
  } catch (error) {
    geoParams.agentes = null;
  }
  try {
    geoParams.empreiteiro = params["tem_empreiteiro"];
  } catch (error) {
    geoParams.empreiteiro = null;
  }
  try {
    geoParams.arquitecto = params["tem_arquitecto"];
  } catch (error) {
    geoParams.arquitecto = null;
  }
  try {
    geoParams.regiao = params["regiao"];
  } catch (error) {
    geoParams.regiao = null;
  }
  try {
    geoParams.concelho = params["concelho"];
  } catch (error) {
    geoParams.concelho = null;
  }
  try {
    geoParams.freguesia__concelho = params["freguesia__concelho"];
  } catch (error) {
    geoParams.freguesia__concelho = null;
  }
  try {
    geoParams.freguesia__concelho__regiao =
      params["freguesia__concelho__regiao"];
  } catch (error) {
    geoParams.freguesia__concelho__regiao = null;
  }
  try {
    geoParams.disponibilidade =
      params["disponibilidade"] === "unknown"
        ? null
        : params["disponibilidade"];
  } catch (error) {
    geoParams.disponibilidade = null;
  }

  return normalizeParams(params);
};

const normalizeParams = (params) => {
  let addParams = "";

  if (
    params["destino"] !== undefined &&
    params["destino"] !== null &&
    params["destino"].length > 0
  ) {
    addParams += `${addParams === "" ? "" : " and"} destino in (${params[
      "destino"
    ].join(",")})`;
  }
  if (
    params["tipo"] !== undefined &&
    params["tipo"] !== null &&
    params["tipo"].length > 0
  ) {
    addParams += `${addParams === "" ? "" : " and"} tipo in (${params[
      "tipo"
    ].join(",")})`;
  }
  if (
    params["classe_energetica"] !== undefined &&
    params["classe_energetica"] !== null &&
    params["classe_energetica"].length > 0
  ) {
    addParams += `${
      addParams === "" ? "" : " and"
    } classe_energetica in (${params["classe_energetica"].join(",")})`;
  }
  if (
    params["data_emissao_after"] !== undefined &&
    params["data_emissao_after"] !== null
  ) {
    if (params["data_emissao_before"] !== null) {
      addParams += `${addParams === "" ? "" : " and"} (data_emissao between '${
        params["data_emissao_after"]
      }' and '${params["data_emissao_before"]}')`;
    } else {
      addParams += `${addParams === "" ? "" : " and"} (data_emissao >= '${
        params["data_emissao_after"]
      }')`;
    }
  }
  if (
    params["alvara_data_after"] !== undefined &&
    params["alvara_data_after"] !== null
  ) {
    if (
      params["alvara_data_before"] !== undefined &&
      params["alvara_data_before"] !== null
    ) {
      addParams += `${addParams === "" ? "" : " and"} (alvara_data between '${
        params["alvara_data_after"]
      }' and '${params["alvara_data_before"]}')`;
    } else {
      addParams += `${addParams === "" ? "" : " and"} (alvara_data >= '${
        params["alvara_data_after"]
      }')`;
    }
  }
  if (
    params["area_construcao_min"] !== undefined &&
    params["area_construcao_min"] !== ""
  ) {
    if (params["area_construcao_max"] !== "") {
      addParams += `${
        addParams === "" ? "" : " and"
      } (area_construcao between '${params["area_construcao_min"]}' and '${
        params["area_construcao_max"]
      }')`;
    } else {
      addParams += `${addParams === "" ? "" : " and"} (area_construcao >= '${
        params["area_construcao_min"]
      }')`;
    }
  }
  if (params["nr_fracoes_min"]) {
    if (params["nr_fracoes_max"]) {
      addParams += `${addParams === "" ? "" : " and"} (nr_fracoes between '${
        params["nr_fracoes_min"]
      }' and '${params["nr_fracoes_max"]}')`;
    } else {
      addParams += `${addParams === "" ? "" : " and"} (nr_fracoes >= '${
        params["nr_fracoes_min"]
      }')`;
    }
  }
  if (params["created_date_after"]) {
    if (params["created_date_before"]) {
      addParams += `${addParams === "" ? "" : " and"} (created_date between '${
        params["created_date_after"]
      }' and '${params["created_date_before"]}')`;
    } else {
      addParams += `${addParams === "" ? "" : " and"} (created_date >= '${
        params["created_date_after"]
      }')`;
    }
  }


  for (let i in geoParams) {
    if (
      geoParams[i] === "" ||
      geoParams[i] === null ||
      geoParams[i] === undefined ||
      geoParams[i] === false
    ) {
      delete geoParams[i];
    }
  }

  let geoParams_normalize = queryString.stringify(geoParams);

  if (geoParams_normalize === "") {
    geoParams_normalize += addParams;
  } else {
    geoParams_normalize += addParams !== "" ? ` and ${addParams}` : "";
  }

  return geoParams_normalize.replaceAll("&", " and ");
};

export default processGeoserverUrl;
