import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import "./NavBar.scss";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import "../../assets/js/ciApps.js";

const NavBar = () => {
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);

  return (
    <Navbar bg="light" expand="lg" className="pipeline-navbar">
      <Navbar.Brand href="/">
        <img
          src="/static/frontend/media/logo-pipeline.a3ce529c.svg"
          alt="Logo"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="nav-bar-items justify-content-end">
          {user.authenticated && (
            <>
              <Link
                className={
                  history.location.pathname.includes("/edificios-em-projecto")
                    ? "nav-link active"
                    : "nav-link"
                }
                to={"/edificios-em-projecto"}
              >
                edificios em projecto
              </Link>
              <Link
                className={
                  history.location.pathname.includes("/edificios-em-obra")
                    ? "nav-link active"
                    : "nav-link"
                }
                to={"/edificios-em-obra"}
              >
                edificios em obra
              </Link>
              {/*{user.admin && (*/}
              <Link
                className={
                  history.location.pathname.includes(
                    "/edificios-em-comercializacao"
                  )
                    ? "nav-link active"
                    : "nav-link"
                }
                to={"/edificios-em-comercializacao"}
              >
                edificios em comercializacao
              </Link>
              {/*)}*/}
              <a className={"nav-link disabled"} href="#">
                |
              </a>
              <Link
                className={
                  history.location.pathname.includes("/os-meus-edificios")
                    ? "nav-link active"
                    : "nav-link"
                }
                to={"/os-meus-edificios"}
              >
                os meus edificios
              </Link>
              <a className={"nav-link disabled"} href="#">
                |
              </a>
              <a
                hidden={!user.admin}
                className="nav-link"
                href={user.admin_url}
              >
                ADMIN
              </a>
            </>
          )}

          <a
            className={"nav-link"}
            href={user.authenticated ? "/accounts/logout/" : "/accounts/login/"}
          >
            {user.authenticated ? "Logout" : "Login"}
          </a>

          <div className="apps-wrapper">
            <ci-apps></ci-apps>
          </div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
