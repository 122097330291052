import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";
import Toolbar from "../components/Toolbar";
import API, { ExportApi } from "../redux/configs/api";
import { connect } from "react-redux";
import clsx from "clsx";
import SplitterLayout from "react-splitter-layout";
import MapComponent from "../components/Map/MapComponent";
import LoadingMask from "../components/LoadingMask";
import InfiniteScroll from "react-infinite-scroll-component";
import Masonry from "react-masonry-component";
import CardComponent from "../components/Card";
import { layerGroup, CircleMarker } from "leaflet";
import List from "../components/List";
import { columnBandsEmp, columnsEmp } from "../configs/configs";
import Select from "react-select";
import { setZomm } from "../redux/actions/map";
import { DateFilter } from "../components/Filters/DateFilter";
import { AreasFilter } from "../components/Filters/AreaFilter";
import { CheckBoxFilter } from "../components/Filters/CheckBoxFilter";
import {
  filterEmpreendimentos,
  getEmpreendimentos,
  getSearchFields,
  loadOptions,
  setBboxSelect,
  setFilterParams,
  setSearchValue,
  setSettings,
  setBookMarkEmp,
} from "../redux/actions/empreendimento";
import Async from "react-select/async/dist/react-select.esm";
import swal from "@sweetalert/with-react";

class ComercialicacaoContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      moreFilters: true,
      regConDefaultOptions: [],
      origin: null,
      bboxSelect: null,
      show_data_emissao_after: null,
      show_data_emissao_before: null,
      mainView: "cards",
      markers: [],
      rightView: "cards",
      mark: layerGroup(),
      isClean: false,
      cardSelected: null,
      show: false,
      target: null,
      updateWidth: false,
    };
  }

  componentDidMount() {
    if (this.props.empreendimentos.data.results.length === 0) {
      this.props.getEmpreendimentos();
      this.props.getSearchFields();
      this.setRegConOptions();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.empreendimentos.params !== this.props.empreendimentos.params
    ) {
      this.props.filterEmpreendimentos(this.props.empreendimentos.params);
      this.setState({ isClean: false });
    }

    if (!this.state.updateWidth) {
      let wrapper = document.querySelector(".map .layout-pane:first-child");
      wrapper.style.width = "30%";
      window.dispatchEvent(new Event("resize"));
      this.setState({ updateWidth: true });
    }
  }

  componentWillUnmount() {
    const bboxCome = window.leafletMap.getBounds();
    const bbox = [
      bboxCome.getNorthEast().lng,
      bboxCome.getNorthEast().lat,
      bboxCome.getSouthWest().lng,
      bboxCome.getSouthWest().lat,
    ];
    this.props.setBboxSelect({
      ...this.props.empreendimentos.settings.bbox_selected,
      bbox: bbox,
    });
  }

  setRegConOptions() {
    API.get(`empreendimento/regiao-concelho/`).then((response) => {
      let list = [];
      for (let x of response.data) {
        list.push({
          value: x.id,
          label: x.name,
          bbox: x.bbox,
          origin: x.origin,
        });
      }
      this.setState({ regConDefaultOptions: list });
    });
  }

  handlerChange(e) {
    if (e === null || e.length === 0) {
      this.props.setFilterParams({
        regiao: null,
        concelho: null,
      });
      this.props.setBboxSelect(null);
    } else {
      this.props.setFilterParams({
        [this.origin]: null,
        [e.origin]: e.value,
      });
      this.props.setBboxSelect(e);
      this.setState({
        origin: this.origin !== e.origin ? e.origin : this.origin,
      });
    }
  }

  handlerChangeDisponibilidade(e) {
    this.props.setFilterParams({
      disponibilidade: e !== null ? e.value : "unknown",
    });
    this.props.setSettings({ disponibilidade: e });
  }

  exportData() {
    const count = this.props.empreendimentos.data.count;
    let msg = "Extração limitada a 250 registos. Deseja os 250 mais recentes?";
    if (count < 250) {
      msg = `Deseja extrair os ${count} registos?`;
    }
    swal({
      text: msg,
      buttons: {
        cancel: "Não",
        catch: {
          text: "Sim",
          value: true,
        },
      },
    }).then((value) => {
      if (value) {
        ExportApi.get("empreendimento.xlsx/", {
          params: this.props.empreendimentos.params,
        }).then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/vnd.ms-excel" })
          );
          var link = document.createElement("a");
          link.href = url;
          const date = new Intl.DateTimeFormat("pt", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
            .format(new Date())
            .replaceAll("/", "_");
          link.download = `Edf_Comer_Export_${date}.xlsx`;
          document.body.appendChild(link); // we need to append the element to the dom -> otherwise it will not work in firefox
          link.click();
          link.remove(); //afterwards we remove the element again
        });
      }
    });
  }

  onDragEnd() {
    window.dispatchEvent(new Event("resize"));
    let tb = document.querySelector(".topBarWrapper");
    let cardsWrapper = document.querySelector(".map .layout-pane-primary");
    if (cardsWrapper.offsetWidth < 584) {
      tb.style.display = "none";
    } else {
      tb.style.display = "block";
    }
  }

  setNewMarker(incomeMarker) {
    let mList = this.state.markers,
      exist = mList.includes(incomeMarker);
    if (!exist) {
      mList.push(incomeMarker);
      this.setState({ markers: mList });
    }
  }

  onItemMouseOver(item) {
    window.leafletMap.removeLayer(this.state.mark);
    this.state.mark.clearLayers();
    this.state.mark
      .addLayer(
        new CircleMarker([item.gps.coordinates[1], item.gps.coordinates[0]], {
          id: item.id,
          fillOpacity: 1,
          stroke: true,
          color: "white",
          radius: 15,
          fillColor:
            item.tipo_id === 1
              ? "#246db7"
              : item.tipo_id === 2
              ? "#f9ba04"
              : "#7b7c7b",
        })
      )
      .addTo(window.leafletMap);
  }

  filterBySearchFields() {
    this.props.setFilterParams({ ...this.props.empreendimentos.searchFilters });
  }

  clearShearField() {
    let fields = { ...this.props.empreendimentos.searchFilters };
    for (let f in fields) {
      fields[f] = "";
    }
    this.props.setSearchValue(fields);
    this.props.setFilterParams({ ...fields });
  }

  getYearMonthNow() {
    return new Date().toJSON().slice(0, 7).replace(/-/g, "/");
  }

  handlerFogosChange(e, clear = false) {
    if (!clear) {
      this.props.setFilterParams({
        [e.target.name]: e.target.value !== "" ? parseInt(e.target.value) : "",
      });
    } else {
      this.props.setFilterParams({
        nr_fracoes: "",
      });
    }
  }

  handlerFogosFilter(e, clear = false) {
    if (!clear) {
      this.props.setFilterParams({
        [e.target.name]: e.target.value !== "" ? parseInt(e.target.value) : "",
      });
    } else {
      this.props.setFilterParams({
        nr_fracoes_min: "",
        nr_fracoes_max: "",
      });
    }
  }

  render() {
    const { filters, params, data, settings, searchFilters, mainView } =
      this.props.empreendimentos;
    const { loading } = this.props.loading;

    const { user } = this.props.auth;
    const { show } = this.state;

    return (
      <section className="main-content">
        {/*MAP AND CARDS*/}
        <div className="mapCardsActions">
          <div className="mapCardsWrapper">
            {/*SPLITTER*/}
            <SplitterLayout
              percentage
              primaryIndex={1}
              customClassName={clsx("splitterClass filters-splitter")}
              onDragEnd={this.onDragEnd}
            >
              <div className="filterWrapper">
                <div className="list">
                  <section
                    className={
                      this.state.moreFilters ? "show-filters" : "toggle-filters"
                    }
                  >
                    {/*CONCELHO*/}
                    <Form.Group>
                      <label>Região / Concelho</label>
                      <Async
                        defaultValue={settings.bbox_selected}
                        value={
                          settings.bbox_selected !== null
                            ? settings.bbox_selected.value !== undefined
                              ? settings.bbox_selected
                              : null
                            : null
                        }
                        placeholder={""}
                        loadOptions={loadOptions}
                        cacheOptions
                        defaultOptions={this.state.regConDefaultOptions}
                        onChange={(e) => this.handlerChange(e)}
                        className="react-select"
                        classNamePrefix="react-select"
                        isClearable={true}
                      />
                    </Form.Group>
                    {/*Disponibilidade*/}
                    <Form.Group>
                      <label>FOGOS EM OFERTA</label>
                      <Select
                        placeholder={""}
                        defaultValue={settings.disponibilidade}
                        isClearable={true}
                        name="disponibilidade"
                        className="react-select"
                        classNamePrefix="react-select"
                        options={[
                          { label: "Sim", value: true },
                          { label: "Não", value: false },
                        ]}
                        onChange={(e) => this.handlerChangeDisponibilidade(e)}
                      />
                    </Form.Group>
                    {/*Nº de fogos*/}
                    <AreasFilter
                      title={"Nº DE FOGOS DO EDIFÍCIO"}
                      params={params}
                      filter_min={"nr_fracoes_min"}
                      filter_max={"nr_fracoes_max"}
                      cleanText={"Limpar fogos"}
                      handlerValuesFilter={(e, bool) =>
                        this.handlerFogosFilter(e, bool)
                      }
                    />
                    {/*Data criacao*/}
                    <Form.Group>
                      <DateFilter
                        min_year={user.empreendimento.min_year}
                        max_year={user.empreendimento.max_year}
                        filterNameBefore="created_date_before"
                        filterNameAfter="created_date_after"
                        selectedFilterNameBefore={
                          settings.show_created_date_before
                        }
                        selectedFilterNameAfter={
                          settings.show_created_date_after
                        }
                        setFilterParams={(_params) =>
                          this.props.setFilterParams(_params)
                        }
                        setSettings={(_settings) =>
                          this.props.setSettings(_settings)
                        }
                        title="Data de lançamento "
                      />
                    </Form.Group>
                  </section>
                </div>
              </div>
              <SplitterLayout
                primaryIndex={1}
                percentage
                secondaryMinSize={0}
                primaryMinSize={0}
                onDragEnd={this.onDragEnd.bind(this)}
                customClassName={clsx(
                  "splitterClass map",
                  this.state.mainView !== "cards" ? "hide" : "show"
                )}
              >
                {/*MAP WRAPPER*/}
                <div className="mapWrapper">
                  <MapComponent
                    hideLegend={true}
                    bboxInit={null}
                    params={params}
                    layers={"ci:empreendimentos"}
                    items={data.results}
                    callBack={(incomeMarker) => this.setNewMarker(incomeMarker)}
                    filter={(param) => this.props.filterEmpreendimentos(param)}
                    setParams={(param) => this.props.setFilterParams(param)}
                    dataType="emp"
                    origin="edificios-em-comercializacao"
                  />
                </div>
                {/*MAP WRAPPER*/}
                {/*CARDS LIST*/}
                <div className="gridList">
                  <Toolbar
                    loading={loading}
                    count={data.count}
                    show={show}
                    mainView={mainView}
                    setState={(e) => this.setState(e)}
                    exportData={() => this.exportData()}
                    search_filters={filters.search_filters}
                    searchFilters={searchFilters}
                    setSearchValue={(e) => this.props.setSearchValue(e)}
                    filterBySearchFields={() => this.filterBySearchFields()}
                    clearShearField={() => this.clearShearField()}
                  />
                  <div className="rightPanelActions">
                    <section className="changeView">
                      <Button disabled={true}>
                        {`${
                          data.count === undefined ? 0 : data.count
                        }  RESULTADOS`}
                      </Button>
                    </section>
                    <section className="counts"></section>
                  </div>
                  {/*CARDS LIST && CHARTS*/}
                  <div id="cardsBox">
                    {loading && <LoadingMask />}
                    <InfiniteScroll
                      className="infiniteScroll"
                      dataLength={data.results.length}
                      next={() => this.props.getEmpreendimentos(data.next)}
                      hasMore={data.next !== null}
                      scrollableTarget="cardsBox"
                      loader={<></>}
                    >
                      {/*MASONRY*/}
                      <Masonry
                        className="masonryContainer"
                        options={masonryOptions}
                      >
                        {data.results.length > 0 &&
                          data.results.map((item, key) => (
                            <CardComponent
                              setBookmarFunc={(pos, status) =>
                                this.props.setBookMarkEmp(pos, status)
                              }
                              emp={true}
                              bookmarkUrl={"empreendimento"}
                              parent={"edificios-em-comercializacao"}
                              mouseOverCallBack={() =>
                                this.onItemMouseOver(item)
                              }
                              mouseLeaveCallBack={() =>
                                console.warn("eliminar")
                              }
                              key={key}
                              position={key}
                              item={item}
                              cardSelected={this.state.cardSelected}
                            />
                          ))}
                      </Masonry>
                      {/*MASONRY*/}
                    </InfiniteScroll>
                  </div>
                  {/*CARDS LIST* && CHARTS*/}
                </div>
              </SplitterLayout>
            </SplitterLayout>
            {/*SPLITTER*/}
            {/*LIST*/}
            {this.state.mainView === "list" && (
              <div className="listWrapper">
                <List
                  parent={"edificios-em-comercializacao"}
                  count={data.count}
                  items={data.results}
                  getData={() => this.props.getEmpreendimentos(data.next)}
                  columnBands={columnBandsEmp}
                  columns={columnsEmp}
                  nextUrl={data.next}
                />
              </div>
            )}
            {/*LIST*/}
          </div>
        </div>
        {/*MAP AND CARDS*/}
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    empreendimentos: state.empreendimentos,
    auth: state.auth,
    loading: state.process,
    map: state.map.map,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEmpreendimentos: (next) => dispatch(getEmpreendimentos(next)),
    getSearchFields: () => dispatch(getSearchFields()),
    setFilterParams: (params) => dispatch(setFilterParams(params)),
    filterEmpreendimentos: (params) => dispatch(filterEmpreendimentos(params)),
    setBboxSelect: (selected) => dispatch(setBboxSelect(selected)),
    setSettings: (settings) => dispatch(setSettings(settings)),
    setZomm: (zoomObject) => dispatch(setZomm(zoomObject)),
    setSearchValue: (searchvalues) => dispatch(setSearchValue(searchvalues)),
    setBookMarkEmp: (pos, status) => dispatch(setBookMarkEmp(pos, status)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComercialicacaoContainer);

const masonryOptions = {
  transitionDuration: "0.5s",
  itemSelector: ".card",
  columnWidth: 300,
  gutter: 10,
  fitWidth: true,
  originLeft: true,
};
