import * as types from "../constants";

/**
 * initialStateLicencas interface
 */
export const initialStateLicencas = {
    data: {
        results: [], next: null, count: 0, previous: null
    }, filters: {
        tipo: [], destino: [], concelhos: [], search_filters: [],
    }, params: {
        concelho: null,
        regiao: null,
        alvara_data_after: null,
        alvara_data_before: null,
        area_construcao_min: '',
        area_construcao_max: '',
        tem_empreiteiro: false,
        tem_arquitecto: false,
        tipo: null,
        destino: null,
    }, settings: {
        chartViewActive: 'fichas', bbox_selected: null, show_alvara_data_after: null, show_alvara_data_before: null
    }, charts: {
        fogosChart: {
            categories: [], data: [], activeData: 'destino',
        }, areaChart: {
            categories: [], data: [], activeData: 'destino',
        }, tpyChart: {
            categories: [], data: [],
        },
    }, searchFilters: {}, itemDetail: null, filtersActive: {},
};

/**
 * Receives state and action with function type and payload 
 */
export const licencasReducer = (state = initialStateLicencas, action) => {
    switch (action.type) {
        case types.GET_LICENCAS:
            return {
                ...state, data: action.payload,
            };
        case types.GET_MORE_LICENCAS:
            return {
                ...state, data: {
                    count: action.payload.count,
                    next: action.payload.next,
                    previous: action.payload.previous,
                    results: state.data.results.concat(action.payload.results),
                },
            };
        case types.GET_DESTINO_LICENCAS:
            return {
                ...state, filters: {
                    ...state.filters, destino: action.payload
                },
            };
        case types.GET_CONCELHOS_LICENCAS:
            return {
                ...state, filters: {
                    ...state.filters, concelhos: action.payload,
                },
            };
        case types.GET_TIPO_LICENCAS:
            return {
                ...state, filters: {
                    ...state.filters, tipo: action.payload,
                },
            };
        case types.SET_FILTER_PARAMS_LICENCAS:
            const params = {...state.params, ...action.payload};
            return {
                ...state, params: {
                    ...params,
                },
            };
        case types.GET_LICENCA_DETAIL:
            return {
                ...state, itemDetail: action.payload,
            };
        case types.GET_SEARCH_FIELDS_LIC:
            return {
                ...state, filters: {
                    ...state.filters, search_filters: action.payload,
                }
            };
        case types.SET_CONCELHO_LICENCA_SELECTED:
            return {
                ...state, concelhoSelected: action.payload,
            };
        case types.SET_BOOKMARK_LICENCA:
            let data = state.data;
            data.results[action.payload].bookmarked = action.status
            return {
                ...state, data: data
            }
        case types.SET_AREA_LIC_CHART:
            let areaCategories = [], areaData = [];
            for (let item of action.payload) {
                areaCategories.push(item.category);
                areaData.push(item.value);
            }
            return {
                ...state, charts: {
                    ...state.charts, areaChart: {
                        categories: areaCategories,
                        data: areaData,
                        activeData: state.charts.areaChart.activeData,
                        filters: state.charts.areaChart.filters,
                    }
                },
            };
        case types.SET_FOGOS_LIC_CHART:
            let fogosCategories = [], fogosData = [];
            for (let item of action.payload) {
                fogosCategories.push(item.category);
                fogosData.push(item.value);
            }
            return {
                ...state, charts: {
                    ...state.charts, fogosChart: {
                        categories: fogosCategories, data: fogosData, activeData: state.charts.fogosChart.activeData,
                    }
                },
            };
        case types.SET_AREA_CHART_LIC_ACTIVE_DATA:
            return {
                ...state, charts: {
                    ...state.charts, areaChart: {
                        categories: state.charts.areaChart.categories,
                        data: state.charts.areaChart.data,
                        activeData: action.payload,
                    }
                },
            };
        case types.SET_FOGOS_CHART_LIC_ACTIVE_DATA:
            return {
                ...state, charts: {
                    ...state.charts, fogosChart: {
                        categories: state.charts.fogosChart.categories,
                        data: state.charts.fogosChart.data,
                        activeData: action.payload,
                    }
                },
            };
        case types.SET_CHARTS_VIEW_LIC_ACTIVE:
            return {
                ...state, settings: {
                    ...state.settings, chartViewActive: action.payload
                },
            };
        case types.SET_TPY_LIC_CHART:
            let tpyCategories = [], tpyData = [];
            for (let item of action.payload) {
                tpyCategories.push(item.year);
                tpyData.push(item.count);
            }
            return {
                ...state, charts: {
                    ...state.charts, tpyChart: {
                        categories: tpyCategories, data: tpyData, activeData: state.charts.tpyChart.activeData,
                    }
                },
            };
        case types.SET_ACTIVE_FILTERS_LICENCAS:
            return {
                ...state, filtersActive: {
                    ...state.filtersActive, ...action.payload
                }
            }
        case types.SET_LIC_SETTINGS:
            return {
                ...state, settings: {
                    ...state.settings, ...action.payload
                }
            };
        case types.SET_FILTER_PARAMS_SEARCH_LIC:
            return {
                ...state, searchFilters: {
                    ...state.searchFilters, ...action.payload
                }
            }
        case types.SET_BBOX_SELECTED_LIC:
            return {
                ...state, settings: {
                    ...state.settings, bbox_selected: action.payload
                },
            };
        default:
            return {
                ...state,
            };
    }
};
