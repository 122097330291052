import {createStore, applyMiddleware, compose} from "redux";
import thunk from "redux-thunk";
import AppReducer from '../reducers/index';

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store = createStore(AppReducer, composeEnhancers(applyMiddleware(thunk)));

export default store;

