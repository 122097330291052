import React, {} from 'react';
import {Fade} from 'react-slideshow-image';
import {Modal} from 'react-bootstrap';
import 'react-slideshow-image/dist/styles.css'
import './SlideShowComponent.scss'

const SlideShowComponent = ({images, modalShow, hideModal}) => {
    return (
        <Modal className={"modalSlide"} centered size={'lg'} show={modalShow} onHide={()=> hideModal()}>
            <Modal.Body className={"modalSlide-body"}>
                <Fade>
                    {images.map((img, key) => (
                        <div className="each-fade" key={key}>
                            <div>
                                <img src={img} alt={`Foto_${key}_fade`}/>
                            </div>
                        </div>
                    ))}
                </Fade>
            </Modal.Body>

        </Modal>
    );
}

export default SlideShowComponent;
