import * as types from "../constants";

/**
 * initialState interface
 */
export const initialState = {
    loading: false,
}

/**
 * Receives state and action with function type and payload 
 */
export const processReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_LOADING_STATUS:
            return {
                ...state, loading: action.payload
            };
        default:
            return {
                ...state,
            };
    }
};
