import React from 'react';
import { Form } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import pt from 'date-fns/locale/pt'
registerLocale("pt", pt)


export const DateFilter = ({ min_year, max_year, filterNameBefore, filterNameAfter, selectedFilterNameBefore, selectedFilterNameAfter, setFilterParams, setSettings, title }) => {

    const handlerDateFilter = (name, value) => {
        if (value !== null) {
            let date = new Date(value);
            const filterValue = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
            setFilterParams({ [name]: filterValue });
            setSettings({ ['show_' + name]: value })
        } else {
            setFilterParams({ [name]: null });
            setSettings({ ['show_' + name]: null })
        }
    }

    return (
        <Form.Group>
            <label className="label">{title}</label>
            <label className="sublabel" htmlFor="after">Desde:</label>
            <DatePicker
                locale="pt"
                isClearable={true}
                autoComplete={'off'}
                selected={selectedFilterNameAfter}
                onChange={(date) => {
                    handlerDateFilter(filterNameAfter, date);
                }}
                minDate={new Date(`${min_year}/01`)}
                maxDate={new Date(`${max_year}/01`)}
                dateFormat="yyyy-MM"
                showMonthYearPicker
                showFullMonthYearPicker
                showTwoColumnMonthYearPicker
            />
            <label className={"sublabel"} htmlFor="before">Até:</label>
            <DatePicker
                locale="pt"
                isClearable={true}
                autoComplete={'off'}
                selected={selectedFilterNameBefore}
                onChange={(date) => {
                    handlerDateFilter(filterNameBefore, date);
                }}
                minDate={new Date(`${min_year}/01`)}
                maxDate={new Date(`${max_year}/01`)}
                dateFormat="yyyy-MM"
                showMonthYearPicker
                showFullMonthYearPicker
                showTwoColumnMonthYearPicker
            />
        </Form.Group>
    )

}
