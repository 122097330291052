import React, {useRef} from "react";
import PropTypes from 'prop-types';
import {GridExporter} from '@devexpress/dx-react-grid-export';
import {
    VirtualTableState,
    SortingState,
    IntegratedSorting,
    DataTypeProvider
} from '@devexpress/dx-react-grid';

import {
    Grid,
    VirtualTable,
    TableHeaderRow,
    TableBandHeader
} from '@devexpress/dx-react-grid-material-ui';
import '@devexpress/dx-react-grid-material-ui';
import saveAs from 'file-saver';
import './List.scss'
import {Link} from "react-router-dom";

const List = (props) => {

    const exporterRef = useRef();


    const getMoreRows = () => {
        if (props.nextUrl === 'bookmarks') {
            props.getData()
        } else {
            if (props.nextUrl !== null) {
                props.getData(props.nextUrl);
            }
        }
    }

    const onSave = (workbook) => {
        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], {type: 'application/octet-stream'}), `${props.parent}.xlsx`);
        });
    };


    const MakeRefLink = ({value}) => <Link to={`${props.parent}/${value}`} target="_blank">{value}</Link>;

    const DateTypeProvider = args => {
        return (
            <DataTypeProvider
                formatterComponent={MakeRefLink}
                {...args}
            />
        )
    };

    return props.items.length > 0 && (
        <div className="card grid-list-items">
            <Grid
                rows={props.items}
                columns={props.columns}
            >
                <SortingState
                    defaultSorting={props.sortColumns}/>
                <VirtualTableState
                    infiniteScrolling
                    loading={false}
                    totalRowCount={props.count}
                    pageSize={props.items.length > 40 ? 40 : 20}
                    skip={0}
                    getRows={getMoreRows}
                />
                <DateTypeProvider for={['id']}/>
                <VirtualTable height={window.innerHeight - 195}/>
                <IntegratedSorting/>
                <TableHeaderRow/>
                <TableBandHeader
                    columnBands={props.columnBands}
                />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={props.items}
                columns={props.columns}
                onSave={onSave}
            />
        </div>
    );
};

List.propTypes = {
    items: PropTypes.array.isRequired,
    count: PropTypes.number.isRequired,
    nextUrl: PropTypes.string.isRequired,
    getData: PropTypes.func.isRequired,
    columns: PropTypes.array.isRequired,
    columnBands: PropTypes.array.isRequired,
    parent: PropTypes.string.isRequired,
    export: PropTypes.func,
}

export default List;
