import React from "react";
import Table from "react-bootstrap/Table";

const GERAIS = {
    destino: "Destino / Sector:",
    destino_detalhe: "Destino / Detalhe:",
    tipo: "Tipo de obra",
    fase: "Fase de construção:",
    data_emissao: "Data de emissão:",
};
const FISICOS = {
    area_construcao: "Área de construção:",
    n_fogos_total: "N.º total de fogos:",
    n_pisos_acima: "Nº pisos acima do solo:",
    n_quartos_1: "Nº de fogos T1 ou inf.:",
    n_quartos_2: "Nº de fogos T2:",
    n_quartos_3: "Nº de fogos T3:",
    n_quartos_4_mais: "Nº de fogos T4 ou sup.:",
    outras_tipologias: "Outras Tipologias",
    classe_energetica: "Classe energética:",
};
const LOCALIZACAO = {
    concelho: "Concelho:",
    freguesia: "Freguesia:",
    morada: "Endereço:",
    cp: "Código-Postal:",
    gps: "Coordenadas:",
};
const PROCESSO = {
    alvara: 'Nº do Alvará:',
    duracao: 'Duracao:',
    processo_cm: 'Nº do processo CM:',
    artigo_matricial: "Nº de Artigo Matricial:",
    conservatoria_nome: "Conservatória Nome:",
    conservatoria_numero: "Conservatória Número:"
}

const Dados = ({dados_gerais, dados_fisicos, processo, localizacao}) => {

    const checkProcesso = () => {
        const count = Object.keys(processo).filter((key) => processo[key] !== "");
        return count.length > 0
    }

    return (
        <div className="dadosGerais">
            <div className="gerais">
                <h3 className="tableTitle">DADOS GERAIS</h3>
                <Table striped bordered hover>
                    <tbody>
                    {Object.keys(dados_gerais).map((key, index) => {
                        if (dados_gerais[key] !== null && dados_gerais[key] !== "") {
                            return (
                                <tr key={index}>
                                    <td className="tableLeft">{GERAIS[key]}</td>
                                    <td className="tableRight">{dados_gerais[key]}</td>
                                </tr>
                            );
                        }
                        return null
                    })}
                    </tbody>
                </Table>
            </div>

            <div className="fisicos">
                <h3 className="tableTitle">DADOS FÍSICOS</h3>
                <Table striped bordered hover>
                    <tbody>
                    {Object.keys(dados_fisicos).map((key, index) => {
                        if (
                            dados_fisicos[key] !== null &&
                            dados_fisicos[key] !== "" &&
                            dados_fisicos[key] !== 0
                        ) {
                            return (
                                <tr key={index}>
                                    <td className="tableLeft">{FISICOS[key]}</td>
                                    <td className="tableRight">{dados_fisicos[key]}</td>
                                </tr>
                            );
                        }
                        return null
                    })}
                    </tbody>
                </Table>
            </div>

            {
                checkProcesso() ?
                    (
                        <div className="processo">
                            <h3 className="tableTitle">PROCESSO</h3>
                            <Table striped bordered hover>
                                <tbody>
                                {Object.keys(processo).map((key, index) => {
                                    if (processo[key] !== null && processo[key] !== "") {
                                        return (
                                            <tr key={index}>
                                                <td className="tableLeft">{PROCESSO[key]}</td>
                                                <td className="tableRight">
                                                    {processo[key]} {key === 'duracao' ? '(meses)' : ''}
                                                </td>
                                            </tr>
                                        );
                                    }
                                    return null
                                })}
                                </tbody>
                            </Table>
                        </div>
                    ) : null
            }

            <div className="localizacao">
                <h3 className="tableTitle">LOCALIZAÇÃO</h3>
                <Table striped bordered hover>
                    <tbody>
                    {Object.keys(localizacao).map((key, index) => {
                        if (localizacao[key] !== null && localizacao[key] !== "") {
                            return (
                                <tr key={index}>
                                    <td className="tableLeft">{LOCALIZACAO[key]}</td>
                                    <td className="tableRight">
                                        {key !== "gps"
                                            ? localizacao[key]
                                            : `${localizacao.gps.coordinates[1]} , ${localizacao.gps.coordinates[0]}`}
                                    </td>
                                </tr>
                            );
                        }
                        return null
                    })}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default Dados;
