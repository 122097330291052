import * as types from "../constants";

/**
 * initialStateEmpreendimento interface
 */
const initialStateEmpreendimento = {
  data: {
    results: [],
    count: 0,
    next: null,
    previous: null,
  },
  filters: {
    search_filters: [],
  },
  params: {
    disponibilidade: "unknown",
    freguesia__concelho: null,
    freguesia__concelho__regiao: null,
    nr_fracoes_min: null,
    nr_fracoes_max: null
  },
  settings: {
    bbox_selected: null,
    disponibilidade: false,
  },
  searchFilters: {},
  filtersActive: {},
  itemDetail: null,
};

/**
 * Receives state and action with function type and payload
 */
export const empreendimentosReducer = (
  state = initialStateEmpreendimento,
  action
) => {
  switch (action.type) {
    case types.GET_EMPREENDIMENTOS:
      return {
        ...state,
        data: {
          ...action.payload,
        },
      };
    case types.GET_MORE_EMPREENDIMENTOS:
      return {
        ...state,
        data: {
          ...action.payload,
          results: state.data.results.concat(action.payload.results),
        },
      };
    case types.GET_EMPREENDIMENTO_DETAIL:
      return {
        ...state,
        itemDetail: action.payload,
      };
    case types.GET_SEARCH_FIELDS_EMP:
      return {
        ...state,
        filters: {
          ...state.filters,
          search_filters: action.payload,
        },
      };
    case types.GET_CONCELHOS_EMPREENDIMENTOS:
      return {
        ...state,
        concelhos: action.payload,
      };
    case types.SET_CONCELHO_EMP_SELECTED:
      return {
        ...state,
        concelhoSelected: action.payload,
      };
    case types.SET_BOOKMARK_EMPREENDIMENTOS:
      let data = state.data;
      data.results[action.payload].bookmarked = action.status;
      return {
        ...state,
        data: data,
      };
    case types.SET_PARAMS_EMP:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.payload,
        },
      };
    case types.SET_ACTIVEFILTERS_EMP:
      return {
        ...state,
        filtersActive: {
          ...state.filtersActive,
          ...action.payload,
        },
      };
    case types.SET_BBOX_SELECTED_EMP:
      return {
        ...state,
        settings: {
          ...state.settings,
          bbox_selected: action.payload,
        },
      };
    case types.SET_EMP_SETTINGS:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload,
        },
      };
    case types.SET_FILTER_PARAMS_SEARCH_EMP:
      return {
        ...state,
        searchFilters: {
          ...state.searchFilters,
          ...action.payload,
        },
      };
    default:
      return {
        ...state,
      };
  }
};
