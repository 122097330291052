import React from "react";
import * as types from "../constants";
import API from "../configs/api";
import { faStar as faFilledStar, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const getFavorites = (bookmarkUrl, nextUrl = null) => {
  let configs = { params: {} },
    url = `bookmarks/${bookmarkUrl}/add-remove/`;
  if (nextUrl !== null) {
    configs["baseURL"] = nextUrl;
    url = "";
  }
  return async (dispatch) => {
    const response = await API.get(url, configs);
    let list = [];
    for (let x of response.data.results) {
      x[bookmarkUrl]["bookmarked"] = (
        <FontAwesomeIcon className={"bookmark"} icon={faFilledStar} />
      );
      x[bookmarkUrl]["openUrl"] = (
          <FontAwesomeIcon className={"openUrl"} icon={faExternalLinkAlt} />
      );
      list.push(x[bookmarkUrl]);
    }
    dispatch({
      type: types.GET_FAVORITES,
      payload: {
        list: list,
        count: response.data.count,
        next: response.data.next,
      },
    });
  };
};
