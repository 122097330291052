import * as types from "../constants";
import API from "../configs/api";

export const getInfoUser = () => {
  return async (dispatch) => {
    const response = await API.get("config/");
    dispatch({
      type: types.AUTH_USER_INFO,
      payload: response.data,
    });
  };
};
export const getRegiaoPerms = () => {
  return async (dispatch) => {
    const response = await API.get("perms/regiao/");
    dispatch({
      type: types.REGIAO_PERMS,
      payload: response.data.results,
    });
  };
};

export const getConcelhoPerms = () => {
  return async (dispatch) => {
    const response = await API.get("perms/concelho/");
    dispatch({
      type: types.CONCELHO_PERMS,
      payload: response.data.results,
    });
  };
};
