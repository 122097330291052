import PropTypes from 'prop-types';

/**
 * Update proptypes to reflect the types of the properties and attributes
 */
export const propTypes = {
    data: PropTypes.array
};

/**
 * Update this object with the initial values of the properties
 */
export const componentProperties = {
    data: [],
    selection: new Set(),
    isLoading: false,
    headerEmptyMessage: 'NO DATA AVAILABLE',
    contentEmptyMessage: 'There is no data to display.',
    title: 'Set me a boolean or a String',
    description: 'Set me a boolean or a String',
};

/**
 * Update this object with the initial values of the attributes
 */
export const componentAttributes = {
    showClearSection: true,
    visibleCategories: 5,
    displayField: 'name',
    valueField: 'name',
    countField: 'count',
    loadingMask: true
};
