/**
 * Verbose name for Redux calls and catch actions
 */

export const SET_MENU_STATE = "SET_MENU_STATE";
export const SET_MENU_ACTIVE = "SET_MENU_ACTIVE";
export const SET_LOADING_STATUS = "SET_LOADING_STATUS";
//FAVORITES
export const GET_FAVORITES = "GET_FAVORITES";


//PICS
export const SET_FILTER_PARAMS = "SET_FILTER_PARAMS";
export const GET_PICS = "GET_PICS";
export const GET_MORE_PICS = "GET_MORE_PICS";
export const GET_PIC_DETAIL = "GET_PIC_DETAIL";
export const GET_TIPO_PICS = "GET_TIPO_PICS";
export const GET_DESTINO_PICS = "GET_DESTINO_PICS";
export const GET_CE_PICS = "GET_CE_PICS";
export const GET_REGIAO_PICS = "GET_REGIAO_PICS";
export const SET_FILTER_PARAMS_SEARCH = "SET_FILTER_PARAMS_SEARCH";
export const SET_LEFT_FILTERS_ACTIVE = "SET_LEFT_FILTERS_ACTIVE";
export const GET_SEARCH_FIELDS = "GET_SEARCH_FIELDS";
export const SET_CONCELHO_SELECTED = "SET_CONCELHO_SELECTED";
export const SET_BOOKMARK_PIC = "SET_BOOKMARK_PIC";
export const SET_BBOX_SELECTED = "SET_BBOX_SELECTED";
export const SET_ACTIVE_FILTERS_PICS = "SET_ACTIVE_FILTERS_PICS";
export const SET_PICS_SETTINGS = "SET_PICS_SETTINGS";


//LICENCAS
export const GET_LICENCAS = "GET_LICENCAS";
export const GET_MORE_LICENCAS = "GET_MORE_LICENCAS";
export const GET_TIPO_LICENCAS = "GET_TIPO_LICENCAS";
export const GET_CONCELHOS_LICENCAS = "GET_CONCELHOS_LICENCAS";
export const GET_DESTINO_LICENCAS = "GET_DESTINO_LICENCAS";
export const SET_FILTER_PARAMS_LICENCAS = "SET_FILTER_PARAMS_LICENCAS";
export const GET_LICENCA_DETAIL = "GET_LICENCA_DETAIL";
export const GET_SEARCH_FIELDS_LIC = "GET_SEARCH_FIELDS_LIC";
export const SET_CONCELHO_LICENCA_SELECTED = "SET_CONCELHO_LICENCA_SELECTED";
export const SET_BOOKMARK_LICENCA = "SET_BOOKMARK_LICENCA";
export const SET_CHARTS_VIEW_LIC_ACTIVE = "SET_CHARTS_VIEW_LIC_ACTIVE";
export const SET_AREA_LIC_CHART = "SET_AREA_LIC_CHART";
export const SET_FOGOS_LIC_CHART = "SET_FOGOS_LIC_CHART";
export const SET_AREA_CHART_LIC_ACTIVE_DATA = "SET_AREA_CHART_LIC_ACTIVE_DATA";
export const SET_FOGOS_CHART_LIC_ACTIVE_DATA = "SET_FOGOS_CHART_LIC_ACTIVE_DATA";
export const SET_ACTIVE_FILTERS_LICENCAS = "SET_ACTIVE_FILTERS_LICENCAS";
export const SET_LIC_SETTINGS = "SET_LIC_SETTINGS";
export const SET_FILTER_PARAMS_SEARCH_LIC = "SET_FILTER_PARAMS_SEARCH_LIC";
export const SET_BBOX_SELECTED_LIC = "SET_BBOX_SELECTED_LIC";
export const SET_TPY_LIC_CHART = "SET_TPY_LIC_CHART";

//CHARTS
export const SET_CHARTS_VIEW_ACTIVE = "SET_CHARTS_VIEW_ACTIVE";
export const SET_AREA_CHART = "SET_AREA_CHART";
export const SET_FOGOS_CHART = "SET_FOGOS_CHART";
export const SET_AREA_CHART_ACTIVE_DATA = "SET_AREA_CHART_ACTIVE_DATA";
export const SET_FOGOS_CHART_ACTIVE_DATA = "SET_FOGOS_CHART_ACTIVE_DATA";
export const SET_CHARTS_FILTERS = "SET_CHARTS_FILTERS";
export const SET_TPY_PIC_CHART = "SET_TPY_PIC_CHART";


//EMPREENDIMENTOS
export const GET_EMPREENDIMENTOS = "GET_EMPREENDIMENTOS";
export const GET_MORE_EMPREENDIMENTOS = "GET_MORE_EMPREENDIMENTOS";
export const GET_EMPREENDIMENTO_DETAIL = "GET_EMPREENDIMENTO_DETAIL";
export const GET_SEARCH_FIELDS_EMP = "GET_SEARCH_FIELDS_EMP";
export const GET_CONCELHOS_EMPREENDIMENTOS = "GET_CONCELHOS_EMPREENDIMENTOS";
export const SET_CONCELHO_EMP_SELECTED = "SET_CONCELHO_EMP_SELECTED";
export const SET_BOOKMARK_EMPREENDIMENTOS = "SET_BOOKMARK_EMPREENDIMENTOS";
export const SET_PARAMS_EMP = "SET_PARAMS_EMP";
export const SET_ACTIVEFILTERS_EMP = "SET_ACTIVEFILTERS_EMP";
export const SET_BBOX_SELECTED_EMP = "SET_BBOX_SELECTED_EMP";
export const SET_EMP_SETTINGS = "SET_EMP_SETTINGS";
export const SET_FILTER_PARAMS_SEARCH_EMP = "SET_FILTER_PARAMS_SEARCH_EMP";

// AUTH
export const AUTH_USER_INFO = "AUTH_USER_INFO"
export const REGIAO_PERMS = "REGIAO_PERMS"
export const CONCELHO_PERMS = "CONCELHO_PERMS"

// MAP
export const SET_MAP_ZOOM = "SET_MAP_ZOOM"
export const SET_MAP_REF = "SET_MAP_REF"
