import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import API from "../../redux/configs/api.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import {
  faStar as faFilledStar,
  faSearchLocation,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import "./index.scss";
import CeImgae from "../../assets/images/classes_energeticas2.png";

const CardComponent = (props) => {
  const [bookmarkedRequest, setBookmarkedRequest] = useState(
    props.item.bookmarked !== undefined ? props.item.bookmarked : false
  );
  /**
   * Make a POST if status1 | status2 === true or DELETE 
   */
  const updateBookmark = async (status1, status2) => {
    if (status1 || status2) {
      const bookmark = await API.delete(
        `bookmarks/${props.bookmarkUrl}/add-remove/${props.item.id}/`
      );
      if (bookmark.status === 204) {
        props.setBookmarFunc(props.position, false);
        setBookmarkedRequest(false);
      }
    } else {
      const bookmark = await API.post(
        `bookmarks/${props.bookmarkUrl}/add-remove/`,
        {
          [props.bookmarkUrl]: props.item.id,
        }
      );
      if (bookmark.status === 201) {
        props.setBookmarFunc(props.position, true);
        setBookmarkedRequest(true);
      }
    }
  };

  /**
   * Open property detail 
   */
  const openDetail = () => {
    const win = window.open(`/${props.parent}/${props.item.id}`, "_blank");
    win.focus();
  };

  return (
    <Card id={props.item.id} className={"CardComponent"}>
      <Card.Img
        className={"itemImage"}
        variant="top"
        onClick={() => openDetail()}
        src={
          (process.env.NODE_ENV === "development"
            ? "https://pipelineimobiliario.pt"
            : "") + props.item.foto
        }
      />
      <Card.Body>
        {!props.emp ? (
          <div className="info-panel" onClick={() => openDetail()}>
            <div className="data-info">
              <span className="title">Destino:</span>
              <br />
              {props.item.destino}
            </div>
            <div className="data-info">
              <span className="title">Tipo de Construção:</span>
              <br />
              {props.item.tipo}
            </div>
            <div
              className="data-info"
              hidden={
                props.item.area_construcao === 0 ||
                props.item.area_construcao === null
              }
            >
              <span className="title">Área de Construção:</span>
              <br />
              {props.item.area_construcao} m²
            </div>
          </div>
        ) : (
          <div className="info-panel" onClick={() => openDetail()}>
            <div className="data-info">
              <span className="title">{props.item.nome}</span>
            </div>
            <div className="data-info">
              <span className="title">Concelho:</span> {props.item.concelho}
            </div>
            <div
              className="data-info"
              hidden={
                props.item.nr_fracoes === 0 || props.item.nr_fracoes === null
              }
            >
              <span className="title">Nº de frações:</span>{" "}
              {props.item.nr_fracoes}
            </div>
            <div
              className="data-info"
              hidden={props.item.abc === 0 || props.item.abc === null}
            >
              <span className="title">Área de Construção:</span>{" "}
              {props.item.abc} m²
            </div>
          </div>
        )}
        <div className="menu">
          {props.item.classe_energetica_id !== "" &&
          props.item.classe_energetica_id !== null ? (
            <button className="menu-btn disabled">
              <div
                className={`classe_energetica classe_energetica_${props.item.classe_energetica_id}`}
                style={{ background: `url('${CeImgae}')` }}
              />
            </button>
          ) : (
            ""
          )}
          <button
            className="menu-btn"
            onClick={() => props.mouseOverCallBack()}
          >
            <FontAwesomeIcon
              title="Localizar no mapa"
              className={"show-on-map"}
              icon={faSearchLocation}
            />
          </button>
          <button className="menu-btn" onClick={() => openDetail()}>
            <FontAwesomeIcon
              title="Abrir detalhe"
              className={"open-detail"}
              icon={faExternalLinkAlt}
            />
          </button>
          <button
            className="menu-btn"
            onClick={() =>
              updateBookmark(props.item.bookmarked, bookmarkedRequest)
            }
          >
            <FontAwesomeIcon
              title={
                bookmarkedRequest
                  ? "Eliminar favorito"
                  : "Adicionar aos favoritos"
              }
              className={
                bookmarkedRequest
                  ? "favorite-is-favorite filled"
                  : "favorite filled"
              }
              icon={bookmarkedRequest ? faFilledStar : faStar}
            />
          </button>
        </div>
      </Card.Body>
    </Card>
  );
};

CardComponent.propTypes = {
  item: PropTypes.object.isRequired,
  emp: PropTypes.bool,
  mouseOverCallBack: PropTypes.func,
  mouseLeaveCallBack: PropTypes.func,
  parent: PropTypes.string.isRequired,
  bookmarkUrl: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  setBookmarFunc: PropTypes.func.isRequired,
  cardSelected: PropTypes.number,
};

export default CardComponent;
