import * as types from "../constants";
import API from "../configs/api";

/**
 *  Fetch pics | edificios em projecto
 */
export const getPics = () => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_LOADING_STATUS,
      payload: true,
    });
    const response = await API.get("pic/");
    setTimeout(() => {
      dispatch({
        type: types.GET_PICS,
        payload: response.data,
      });
      dispatch({
        type: types.SET_LOADING_STATUS,
        payload: false,
      });
    }, 1000);
  };
};

/**
 * Fetch next page of  pics | edificios em obra
 */
export const getMorePics = (next) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_LOADING_STATUS,
      payload: true,
    });
    const response = await API.get("", { baseURL: next });
    dispatch({
      type: types.GET_MORE_PICS,
      payload: response.data,
    });
    dispatch({
      type: types.SET_LOADING_STATUS,
      payload: false,
    });
  };
};

/**
 * Fetch filter pics | edificios em obra
 */
export const getFilters = () => {
  return async (dispatch) => {
    const response = await API.get(`pic/filter/tipo/`);
    dispatch({
      type: types.GET_TIPO_PICS,
      payload: response.data.results,
    });
    const responseDestino = await API.get(`pic/filter/destino/`);
    dispatch({
      type: types.GET_DESTINO_PICS,
      payload: responseDestino.data.results,
    });
    const responseCE = await API.get(`pic/filter/classe_energetica/`);
    dispatch({
      type: types.GET_CE_PICS,
      payload: responseCE.data.results,
    });
  };
};

/**
 * Filter pics | edificios em obra
 */
export const filterPics = (params) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_LOADING_STATUS,
      payload: true,
    });
    /**
     * Clean pics in Redux
     */
    dispatch({
      type: types.GET_PICS,
      payload: { results: [] },
    });
    setTimeout(async () => {
      const response = await API.get("pic/", { params: params });
      dispatch({
        type: types.GET_PICS,
        payload: response.data,
      });
      /**
       * Fetch Tipo filter with paramss
       */
      var tipoConfigs = { ...params };
      delete tipoConfigs["tipo"];
      const responseTipo = await API.get(`pic/filter/tipo/`, {
        params: tipoConfigs,
      });
      dispatch({
        type: types.GET_TIPO_PICS,
        payload: responseTipo.data.results,
      });
      /**
       * Fetch Destino filter with params
       */
      var destinoConfigs = { ...params };
      delete destinoConfigs["destino"];
      const responseDestino = await API.get(`pic/filter/destino/`, {
        params: destinoConfigs,
      });
      dispatch({
        type: types.GET_DESTINO_PICS,
        payload: responseDestino.data.results,
      });
      /**
       * Fetch CE filter with params
       */
      var ceConfigs = { ...params };
      delete ceConfigs["classe_energetica"];
      const responseCE = await API.get(`pic/filter/classe_energetica/`, {
        params: ceConfigs,
      });
      dispatch({
        type: types.GET_CE_PICS,
        payload: responseCE.data.results,
      });
      dispatch({
        type: types.SET_LOADING_STATUS,
        payload: false,
      });
    }, 1000);
  };
};

/**
 * Receive compare values and fetch counts to design chart
 */
export const getDataChart = (values, categories, params) => {
  return async (dispatch) => {
    const response = await API.get(`pic/chart/${values}/${categories}/`, {
      params: params,
    });
    switch (values) {
      case "n_fogos_total":
        dispatch({
          type: types.SET_FOGOS_CHART,
          payload: response.data.results,
        });
        break;
      case "area_construcao":
        dispatch({
          type: types.SET_AREA_CHART,
          payload: response.data.results,
        });
        break;
      default:
        break;
    }
  };
};

/**
 * Activate diferent compare data in a ceretain chart
 */
export const setChartActive = (chart, activeData) => {
  return (dispatch) => {
    switch (chart) {
      case "AREA":
        dispatch({
          type: types.SET_AREA_CHART_ACTIVE_DATA,
          payload: activeData,
        });
        break;
      case "Nº FOGOS":
        dispatch({
          type: types.SET_FOGOS_CHART_ACTIVE_DATA,
          payload: activeData,
        });
        break;
      default:
        break;
    }
  };
};

export const setDataChartFilters = (filters) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_CHARTS_FILTERS,
      payload: filters,
    });
  };
};

/**
 * Activate chart view
 */
export const setChartViewActive = (status) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_CHARTS_VIEW_ACTIVE,
      payload: status,
    });
  };
};

/**
 * Set filter params to data persistence
 */
export const setFilterParams = (params) => {
  const emptyParams = {
    concelho: null,
    regiao: null,
    data_emissao_after: null,
    data_emissao_before: null,
    area_construcao_min: "",
    area_construcao_max: "",
    agentes: false,
    tipo: null,
    destino: null,
    classe_energetica: null,
  };
  return (dispatch) => {
    dispatch({
      type: types.SET_FILTER_PARAMS,
      payload: Object.keys(params).length === 0 ? emptyParams : params,
    });
  };
};

/**
 * Fetch search fields from backend to be design on search text filter
 */
export const getSearchFields = () => {
  return async (dispatch) => {
    const response = await API("pic/search_filters/");
    dispatch({
      type: types.GET_SEARCH_FIELDS,
      payload: response.data,
    });
  };
};

/**
 * Bookmark item
 */
export const setBookMark = (position, status) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_BOOKMARK_PIC,
      payload: position,
      status: status,
    });
  };
};

/**
 * Set bounding box selected in Regiao/Concelho select
 */
export const setBboxSelect = (selected) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_BBOX_SELECTED,
      payload: selected,
    });
  };
};

/**
 * Set search values to filter data
 */
export const setSearchValue = (searchvalues) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_FILTER_PARAMS_SEARCH,
      payload: searchvalues,
    });
  };
};

/**
 * Set filters active with values to persistence data
 */
export const setActiveFilters = (filter) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_ACTIVE_FILTERS_PICS,
      payload: filter,
    });
  };
};

/**
 * Set initial page settings
 */
export const setSettings = (setting) => {
  const emptySetting = {
    chartViewActive: "fichas",
    bbox_selected: null,
    show_data_emissao_after: null,
    show_data_emissao_before: null,
  };
  return (dispatch) => {
    dispatch({
      type: types.SET_PICS_SETTINGS,
      payload: Object.keys(setting).length === 0 ? emptySetting : setting,
    });
  };
};

/**
 * Fetch especial data chart
 */
export const getDataChartTPY = (params) => {
  return async (dispatch) => {
    const response = await API.get(`pic/total_per_year/`, { params: params });
    dispatch({
      type: types.SET_TPY_PIC_CHART,
      payload: response.data.results,
    });
  };
};
