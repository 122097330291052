import React, {} from 'react';
import Table from "react-bootstrap/Table";

const Fracoes = ({fracao_values}) => {

    return (
        <div className="fracoes">
            <Table striped bordered hover>
                <tbody>
                {
                    fracao_values.map((item, key) => {
                            if (
                                item.valor !== '0' &&
                                item.valor !== '0 / 0 / 0' &&
                                item.valor !== null &&
                                item.valor !== '') {
                                return (
                                    <tr>
                                        <td className="tableLeft">{item.label}:</td>
                                        <td className="tableRight">{item.valor}</td>
                                    </tr>
                                )
                            }
                            return null
                        }
                    )
                }
                </tbody>
            </Table>
        </div>
    );
}

export default Fracoes;
