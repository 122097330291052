import React, { Component } from "react";
import { propTypes } from "./componentProperties";
import "./Categories.scss";
import { DataProcess } from "./DataProcessment";
import { connect } from "react-redux";
import {
    filterPics,
    getDataChart,
    setDataChartFilters,
    setFilterParams,
    setActiveFilters
} from "../../redux/actions/pics";
import {
    filterLicencas,
    setFilterParamsLicencas,
    setActiveFilters as setActiveFiltersLicencas
} from "../../redux/actions/licencas";

class Categories extends Component {
    constructor(props, context) {
        super(props);
        this.state = {
            parentClean: false,
            selectedCategories: this.props.isPics ?
                this.props.pics.filtersActive[this.props.filter] !== undefined ? this.props.pics.filtersActive[this.props.filter] : new Set() :
                this.props.licencas.filtersActive[this.props.filter] !== undefined ? this.props.licencas.filtersActive[this.props.filter] : new Set(),
            // _currentPropsSelection: this.props.selection,
        };
        // this.dispatchEvent = context;
    }

    // static getDerivedStateFromProps(props, state) {
    //     if (props.selection !== state._currentPropsSelection) {
    //         return {
    //             selectedCategories: props.selection,
    //             _currentPropsSelection: props.selection,
    //         };
    //     }
    //     return null;
    // }

    onSelectedCategory(item) {
        let { selectedCategories } = this.state,
            { valueField } = this.props,
            value = item[valueField],
            selected = selectedCategories.has(value);
        selected ? this.removeFromSelection(value) : this.addToSelection(value);
        // this.fireSelectionEvent();
    }

    fireSelectionEvent() {
        // let {selectedCategories} = this.state,
        //     event = new CustomEvent('selectionChange', {'detail': selectedCategories});
        // console.log(event)
        // this.dispatchEvent(event);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isClean && !prevState.parentClean) {
            this.clearSelection();
            this.setState({parentClean: true})
        } else {
            if(prevState.parentClean){
                this.setState({parentClean: false})
            }
        }
    }

    addToSelection(item) {
        let { selectedCategories } = this.state;
        selectedCategories.add(item);
        this.setState(
            {
                selectedCategories: selectedCategories,
            },
            () => {
                this.setParamsRedux();
            }
        );
    }

    removeFromSelection(item) {
        let { selectedCategories } = this.state;
        selectedCategories.delete(item);
        this.setState(
            {
                selectedCategories: selectedCategories,
            },
            () => {
                this.setParamsRedux();
            }
        );
    }

    clearSelection() {
        this.setState(
            {
                selectedCategories: new Set(),
            },
            () => {
                if (this.props.isPics) {
                    this.props.setFilterParams({ [this.props.filter]: null })
                } else {
                    this.props.setFilterParamsLicencas({ [this.props.filter]: null })
                }
                this.setParamsRedux();
            }
        );
    }

    setParamsRedux() {
        const selectedCategories = this.state.selectedCategories;
        let stringParams = [];
        for (let str of selectedCategories) {
            stringParams.push(str);
        }
        if (this.props.isPics) {
            let params = { ...this.props.params };
            params[this.props.filter] = stringParams;
            this.props.filterData(params);
            this.props.setFilterParams(params);
            this.props.setActiveFiltersPics({ [this.props.filter]: selectedCategories });
        } else {
            let params = { ...this.props.params };
            params[this.props.filter] = stringParams;
            this.props.filterLicencas(params)
            this.props.setFilterParamsLicencas(params);
            this.props.setActiveFiltersLicencas({ [this.props.filter]: selectedCategories });
        }
    }

    render() {
        let {
            isLoading,
            loadingMask,
            title,
            description,
            showClearSection,
            data,
            visibleCategories,
            headerEmptyMessage,
            contentEmptyMessage,
            displayField,
            valueField,
            countField,
        } = this.props,
            { selectedCategories } = this.state,
            totalSelected = selectedCategories.size,
            selectionMode = totalSelected > 0,
            maxValue,
            otherCount = 0,
            sortedData =
                data && Array.isArray(data)
                    ? data.sort((a, b) => (a[countField] < b[countField] ? 1 : -1))
                    : [];
        maxValue =
            sortedData && Array.isArray(sortedData) && sortedData.length > 0
                ? sortedData[0][countField]
                : 0;
        return (
            <div className="app ft-category">
                {loadingMask && isLoading ? (
                    <div className="ft-category-loading-mask" />
                ) : null}
                {title ? <h2 className="ft-category-title">{title}</h2> : null}
                {description ? (
                    <span className="ft-category-description">{description}</span>
                ) : null}
                {showClearSection && sortedData.length > 0 ? (
                    <section className="ft-category-clear-section">
                        <span className="ft-category-clear-section-text">
                            {totalSelected > 0 ? totalSelected : "Todos"}{" "}
                            {totalSelected === 1 ? "selecionado" : "selecionados"}
                        </span>
                        {totalSelected > 0 ? (
                            <button
                                className="ft-category-clear-section-button"
                                onClick={this.clearSelection.bind(this)}
                            >
                                Limpar Filtros
                                {/*Clear Selection*/}
                            </button>
                        ) : null}
                    </section>
                ) : null}
                {sortedData.length > 0 ? (
                    <ul
                        className={
                            "ft-category-ul " +
                            (selectionMode ? "ft-category-selection-mode" : "")
                        }
                    >
                        {sortedData.map((item, index) => {
                            if (index < visibleCategories) {
                                return this.getListElement(
                                    item,
                                    index,
                                    displayField,
                                    valueField,
                                    countField,
                                    maxValue
                                );
                            } else {
                                otherCount += item[countField];
                                return null;
                            }
                        })}
                        {otherCount > 0
                            ? this.renderOtherItem(
                                displayField,
                                valueField,
                                countField,
                                maxValue,
                                otherCount
                            )
                            : null}
                    </ul>
                ) : (
                        <React.Fragment>
                            <span className="ft-category-header-empty-message">
                                {headerEmptyMessage}
                            </span>
                            <span className="ft-category-content-empty-message">
                                {contentEmptyMessage}
                            </span>
                        </React.Fragment>
                    )}
            </div>
        );
    }

    getListElement(item, index, displayField, valueField, countField, maxValue) {
        let { isOther } = item,
            { selectedCategories } = this.state;
        return (
            <li key={index} className="ft-category-li">
                <button
                    className={
                        "ft-category-button ft-category-category-" +
                        (isOther ? "not-selectable" : "selectable")
                    }
                    onClick={this.onSelectedCategory.bind(this, item)}
                    data-selected={selectedCategories.has(item[valueField])}
                >
                    <div className="ft-category-button-header">
                        <span className="ft-category-button-header-title">
                            {item[displayField]}
                        </span>
                        <span className="ft-category-button-header-count">
                            {DataProcess.abbreviateNumber(item[countField])}
                        </span>
                    </div>
                    <div
                        className={
                            "ft-category-button-progress-bar" + (isOther ? "-other" : "")
                        }
                        style={{
                            width: (item[countField] * 100) / maxValue + "%",
                        }}
                    />
                </button>
            </li>
        );
    }

    renderOtherItem(displayField, valueField, countField, maxValue, otherCount) {
        let otherItem = {
            isOther: true,
        };
        otherItem[valueField] = null;
        otherItem[displayField] = "Other";
        otherItem[countField] = otherCount;
        return this.getListElement(
            otherItem,
            0,
            displayField,
            valueField,
            countField,
            maxValue
        );
    }
}

Categories.propTypes = propTypes;

const mapStateToProps = (state, props) => {
    return {
        fogosChart: state.pics.fogosChart || [],
        areaChart: state.pics.areaChart || [],
        chartViewActive: state.pics.chartViewActive,
        pics: state.pics,
        licencas: state.licencas,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        //PICS
        filterData: (params) => dispatch(filterPics(params)),
        getDataChart: (values, categories, params, view) =>
            dispatch(getDataChart(values, categories, params, view)),
        setDataChartFilters: (filters) =>
            dispatch(setDataChartFilters(filters)),
        setFilterParams: (params) => dispatch(setFilterParams(params)),
        // LICENCAS
        filterLicencas: (params) => dispatch(filterLicencas(params)),
        setFilterParamsLicencas: (param) => dispatch(setFilterParamsLicencas(param)),
        setActiveFiltersPics: (filter) => dispatch(setActiveFilters(filter)),
        setActiveFiltersLicencas: (filter) => dispatch(setActiveFiltersLicencas(filter))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
