import React, {Component} from "react";
// COMPONENTS
import {Button, Form} from "react-bootstrap";
import {AsyncPaginate} from "react-select-async-paginate";
import Categories from "../components/Categories/Categories";
import SplitterLayout from "react-splitter-layout";
import MapComponent from "../components/Map/MapComponent";
import LoadingMask from "../components/LoadingMask";
import InfiniteScroll from "react-infinite-scroll-component";
import Charts from "../components/Charts/Charts";
import Masonry from "react-masonry-component";
import CardComponent from "../components/Card";
import {layerGroup, CircleMarker} from "leaflet";
import List from "../components/List";
import swal from "@sweetalert/with-react";
// ICONS
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChartBar,
    faChevronCircleDown,
    faChevronCircleUp,
    faFilter, faList, faThLarge,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import {connect} from "react-redux";
import API, {ExportApi} from "../redux/configs/api";
import {
    filterPics,
    getPics,
    getFilters,
    setFilterParams,
    getMorePics,
    setBboxSelect,
    getSearchFields,
    getDataChart,
    setChartViewActive,
    setSettings,
    setSearchValue,
    getDataChartTPY,
    setBookMark,
} from "../redux/actions/pics";
import {columnBandsPics, columnsPics} from "../configs/configs";
// STYLES
import clsx from "clsx";
import {DateFilter} from "../components/Filters/DateFilter";
import {AreasFilter} from "../components/Filters/AreaFilter";
import {CheckBoxFilter} from "../components/Filters/CheckBoxFilter";
import {setMapRef} from "../redux/actions/map";
import Toolbar from "../components/Toolbar";
import {SmallToolbar} from "../components/SmallToolbar/SmallToolbar";

class PicContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            moreFilters: true,
            regConDefaultOptions: [],
            origin: null,
            bboxSelect: null,
            show_data_emissao_after: null,
            show_data_emissao_before: null,
            mainView: "cards",
            markers: [],
            rightView: "cards",
            mark: layerGroup(),
            isClean: false,
            cardSelected: null,
            show: false,
            target: null,
            regiaoConcelhoNext: null,
            updateWidth: false
        };
    }

    componentDidMount() {
        this.setRegConOptions();
        if (this.props.pics.data.results.length === 0) {
            this.props.getPics();
            this.props.getFilters();
            this.props.getSearchFields();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.pics.params !== this.props.pics.params) {
            this.props.filterPics(this.props.pics.params);
            this.updateDataChart();
            this.setState({isClean: false});
        }

        if (!this.state.updateWidth) {
            let wrapper = document.querySelector(".map .layout-pane:first-child");
            wrapper.style.width = "30%";
            window.dispatchEvent(new Event("resize"));
            this.setState({updateWidth: true});
        }
    }

    componentWillUnmount() {
        const bboxCome = window.leafletMap.getBounds();
        const bbox = [
            bboxCome.getNorthEast().lng,
            bboxCome.getNorthEast().lat,
            bboxCome.getSouthWest().lng,
            bboxCome.getSouthWest().lat,
        ];
        this.props.setBboxSelect({
            ...this.props.pics.settings.bbox_selected,
            bbox: bbox,
        });
    }

    setRegConOptions() {
        API.get(`pic/regiao-concelho/`).then((response) => {
            let list = [];
            for (let x of response.data.results) {
                list.push({
                    value: x.location_id,
                    label: x.name,
                    bbox: x.bbox,
                    origin: x.origin,
                });
            }
            this.setState({
                regConDefaultOptions: list,
                regiaoConcelhoNext: response.data.next,
            });
        });
    }

    async loadOptions2(search, loadedOptions, {page}) {
        const response = await API.get(`pic/regiao-concelho/`, {
            params: {search: search, page: page !== undefined ? page : 1},
        });
        const responseJSON = await response.data;
        const optionsList = [];
        for (let x of responseJSON.results) {
            optionsList.push({
                value: x.location_id,
                label: x.name,
                bbox: x.bbox,
                origin: x.origin,
            });
        }
        return {
            options: optionsList,
            hasMore: responseJSON.next,
            additional: {
                page: page + 1,
            },
        };
    }

    handlerChange(e) {
        if (e === null || e.length === 0) {
            this.props.setFilterParams({
                concelho: null,
                regiao: null,
            });
            this.props.setBboxSelect(null);
        } else {
            this.props.setFilterParams({
                [this.origin]: null,
                [e.origin]: e.value,
            });
            this.props.setBboxSelect(e);
            this.setState({
                origin: this.origin !== e.origin ? e.origin : this.origin,
            });
        }
    }

    handlerAreasFilter(e, clear = false) {
        if (!clear) {
            this.props.setFilterParams({
                [e.target.name]: e.target.value !== "" ? parseInt(e.target.value) : "",
            });
        } else {
            this.props.setFilterParams({
                area_construcao_min: "",
                area_construcao_max: "",
            });
        }
    }

    handlerCheckBox(e) {
        this.props.setFilterParams({[e.target.name]: e.target.checked});
    }

    handlerCleanFilters() {
        this.setState({
            origin: null,
            bboxSelect: null,
            show_data_emissao_after: null,
            show_data_emissao_before: null,
            isClean: true,
        });
        this.props.setBboxSelect(null);
        this.clearShearField();
        this.props.setFilterParams({});
        this.props.setSettings({});
    }

    exportData() {
        const count = this.props.pics.data.count;
        let msg = "Extração limitada a 250 registos. Deseja os 250 mais recentes?";
        if (count < 250) {
            msg = `Deseja extrair os ${count} registos?`;
        }
        swal({
            text: msg,
            buttons: {
                cancel: "Não",
                catch: {
                    text: "Sim",
                    value: true,
                },
            },
        }).then((value) => {
            if (value) {
                ExportApi.get("pic.xlsx/", {
                    params: this.props.pics.params,
                }).then((response) => {
                    const url = window.URL.createObjectURL(
                        new Blob([response.data], {type: "application/vnd.ms-excel"})
                    );
                    var link = document.createElement("a");
                    link.href = url;
                    const date = new Intl.DateTimeFormat("pt", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                    })
                        .format(new Date())
                        .replaceAll("/", "_");
                    link.download = `Edf_Proj_Export_${date}.xlsx`;
                    document.body.appendChild(link); // we need to append the element to the dom -> otherwise it will not work in firefox
                    link.click();
                    link.remove(); //afterwards we remove the element again
                });
            }
        });
    }

    onDragEnd() {
        window.dispatchEvent(new Event("resize"));
        let tb = document.querySelector(".topBarWrapper");
        let rp = document.querySelector(".rightPanelActions");
        let st = document.querySelector(".small-wrapper");
        let cardsWrapper = document.querySelector(".map .layout-pane-primary");
        if (cardsWrapper.offsetWidth < 584) {
            tb.style.display = "none";
            rp.style.display = "none";
            st.classList.add('show');
        } else {
            tb.style.display = "block";
            rp.style.display = "flex";
            st.classList.remove('show');
        }
    }

    setNewMarker(incomeMarker) {
        let mList = this.state.markers,
            exist = mList.includes(incomeMarker);
        if (!exist) {
            mList.push(incomeMarker);
            this.setState({markers: mList});
        }
    }

    updateDataChart() {
        setTimeout(() => {
            const {charts, params, settings} = this.props.pics;
            if (settings.chartViewActive) {
                this.props.getDataChart(
                    "area_construcao",
                    charts.areaChart.activeData,
                    params
                );
                this.props.getDataChart(
                    "n_fogos_total",
                    charts.fogosChart.activeData,
                    params
                );
                this.props.getDataChartTPY(params);
            }
        }, 250);
    }

    onItemMouseOver(item) {
        window.leafletMap.removeLayer(this.state.mark);
        this.state.mark.clearLayers();
        this.state.mark
            .addLayer(
                new CircleMarker([item.gps.coordinates[1], item.gps.coordinates[0]], {
                    id: item.id,
                    fillOpacity: 1,
                    stroke: true,
                    color: "white",
                    radius: 15,
                    fillColor:
                        item.tipo_id === 1
                            ? "#246db7"
                            : item.tipo_id === 2
                                ? "#f9ba04"
                                : "#7b7c7b",
                })
            )
            .addTo(window.leafletMap);
    }

    filterBySearchFields() {
        this.props.setFilterParams({...this.props.pics.searchFilters});
    }

    clearShearField() {
        let fields = {...this.props.pics.searchFilters};
        for (let f in fields) {
            fields[f] = "";
        }
        this.props.setSearchValue(fields);
        this.props.setFilterParams({...fields});
    }

    render() {
        const {filters, params, data, charts, settings, searchFilters} =
            this.props.pics;
        const {loading} = this.props.loading;
        const {user} = this.props.auth;
        const {isClean, show, mainView} = this.state;

        return (
            <section className="main-content">
                <div className="mapCardsActions">
                    <div className="mapCardsWrapper">
                        <SplitterLayout
                            primaryIndex={1}
                            percentage
                            customClassName={clsx("splitterClass filters-splitter")}
                            onDragEnd={this.onDragEnd}
                        >
                            <div className="filterWrapper">
                                <div className="list">
                                    <Button
                                        className="moreFiltersBtn"
                                        onClick={() =>
                                            this.setState({moreFilters: !this.state.moreFilters})
                                        }
                                    >
                                        {this.state.moreFilters ? (
                                            <>
                                                <FontAwesomeIcon icon={faChevronCircleUp}/> FILTROS
                                            </>
                                        ) : (
                                            <>
                                                <FontAwesomeIcon icon={faChevronCircleDown}/> FILTROS
                                            </>
                                        )}
                                    </Button>
                                    <Button
                                        className="cleanFilters"
                                        onClick={() => this.handlerCleanFilters()}
                                    >
                                        <FontAwesomeIcon icon={faFilter}/> LIMPAR
                                    </Button>
                                    <section
                                        className={
                                            this.state.moreFilters ? "show-filters" : "toggle-filters"
                                        }
                                    >
                                        <Form.Group>
                                            <label className="label">Região / Concelho</label>
                                            <AsyncPaginate
                                                value={
                                                    settings.bbox_selected !== null
                                                        ? settings.bbox_selected.value !== undefined
                                                            ? settings.bbox_selected
                                                            : null
                                                        : null
                                                }
                                                loadOptions={this.loadOptions2}
                                                onChange={(e) => this.handlerChange(e)}
                                                additional={{page: 1}}
                                                className="react-select"
                                                classNamePrefix="react-select"
                                                isClearable={true}
                                                placeholder={""}
                                                defaultValue={settings.bbox_selected}
                                            />
                                        </Form.Group>
                                        {user.pic !== undefined && (
                                            <DateFilter
                                                title="Data de emissão"
                                                min_year={user.pic.min_year}
                                                max_year={user.pic.max_year}
                                                filterNameBefore="data_emissao_before"
                                                filterNameAfter="data_emissao_after"
                                                selectedFilterNameBefore={
                                                    settings.show_data_emissao_before
                                                }
                                                selectedFilterNameAfter={
                                                    settings.show_data_emissao_after
                                                }
                                                setFilterParams={(_params) =>
                                                    this.props.setFilterParams(_params)
                                                }
                                                setSettings={(_settings) =>
                                                    this.props.setSettings(_settings)
                                                }
                                            />
                                        )}
                                        <AreasFilter
                                            title={"Área de Construção (m2)"}
                                            params={params}
                                            filter_min={"area_construcao_min"}
                                            filter_max={"area_construcao_max"}
                                            cleanText={"Limpar áreas"}
                                            handlerValuesFilter={(e, bool) =>
                                                this.handlerAreasFilter(e, bool)
                                            }
                                        />
                                        <CheckBoxFilter
                                            checked={params.agentes}
                                            handlerCheckBox={(e) => this.handlerCheckBox(e)}
                                            name="agentes"
                                            label="Promotor Identificado"
                                        />
                                    </section>
                                    <hr/>
                                    <Categories
                                        params={params}
                                        key={"tipo_0"}
                                        {...HistoDummy}
                                        data={filters.tipo}
                                        title={"Tipo De Obra"}
                                        filter={"tipo"}
                                        isPics={true}
                                        isClean={isClean}
                                    />
                                    <hr/>
                                    <Categories
                                        params={params}
                                        key={"destino_0"}
                                        {...HistoDummy}
                                        data={filters.destino}
                                        title={"Destino"}
                                        filter={"destino"}
                                        isPics={true}
                                        isClean={isClean}
                                    />
                                    <hr/>
                                    <Categories
                                        params={params}
                                        key={"ce_0"}
                                        {...HistoDummy}
                                        data={filters.ce}
                                        title={"Classe Energética"}
                                        filter={"classe_energetica"}
                                        isPics={true}
                                        isClean={isClean}
                                    />
                                    <hr/>
                                    <Form.Group className={"information text-muted"}>
                                        <strong>Última atualização de dados:</strong>
                                        <br/>
                                        {user.pic_versao}
                                        <br/>
                                        <strong>Dados inseridos:</strong>
                                        <br/>
                                        {user.pic_dados}
                                    </Form.Group>
                                </div>
                            </div>
                            <SplitterLayout
                                primaryIndex={1}
                                percentage
                                secondaryMinSize={0}
                                primaryMinSize={0}
                                onDragEnd={this.onDragEnd}
                                customClassName={clsx(
                                    "splitterClass map",
                                    this.state.mainView !== "cards" ? "hide" : "show"
                                )}
                            >
                                <div className="mapWrapper">
                                    <MapComponent
                                        bboxInit={null}
                                        params={params}
                                        layers={"ci:pics"}
                                        items={data.results}
                                        callBack={(incomeMarker) => this.setNewMarker(incomeMarker)}
                                        filter={(param) => this.props.filterPics(param)}
                                        setParams={(param) => this.props.setFilterParams(param)}
                                        dataType="pic"
                                        origin="edificios-em-projecto"
                                    />
                                </div>
                                <div className="gridList">
                                    <SmallToolbar
                                        exportData={() => this.exportData()}
                                        changeView={(view) => this.props.setChartViewActive(view)}
                                        updateDataChart={() => this.updateDataChart()}
                                        chartViewActive={settings.chartViewActive}
                                        count={data.count}
                                    />
                                    <Toolbar
                                        loading={loading}
                                        count={data.count}
                                        show={show}
                                        mainView={mainView}
                                        setState={(e) => this.setState(e)}
                                        exportData={() => this.exportData()}
                                        search_filters={filters.search_filters}
                                        searchFilters={searchFilters}
                                        setSearchValue={(e) => this.props.setSearchValue(e)}
                                        filterBySearchFields={() => this.filterBySearchFields()}
                                        clearShearField={() => this.clearShearField()}
                                    />
                                    <div className="rightPanelActions">
                                        <section className="changeView">

                                            <Button
                                                onClick={() => this.props.setChartViewActive("list")}
                                                className={
                                                    settings.chartViewActive === "list" ? "active" : ""
                                                }
                                            >
                                                <FontAwesomeIcon icon={faList}/>
                                            </Button>

                                            <Button
                                                onClick={() => this.props.setChartViewActive("fichas")}
                                                className={
                                                    settings.chartViewActive === "fichas" ? "active" : ""
                                                }
                                            >
                                                <FontAwesomeIcon icon={faThLarge}/>
                                            </Button>

                                            <Button
                                                onClick={() => {
                                                    this.props.setChartViewActive("charts");
                                                    this.updateDataChart();
                                                }}
                                                className={
                                                    settings.chartViewActive === "charts" ? "active" : ""
                                                }
                                            >
                                                <FontAwesomeIcon icon={faChartBar}/>
                                            </Button>
                                        </section>
                                        <section className="counts">
                                            <Button disabled={true}>
                                                {`${
                                                    data.count === undefined ? 0 : data.count
                                                }  RESULTADOS`}
                                            </Button>
                                        </section>
                                    </div>
                                    <div id="cardsBox">
                                        {loading && settings.chartViewActive !== "" ? (
                                            <LoadingMask/>
                                        ) : null}
                                        {settings.chartViewActive === "fichas" ? (
                                            <InfiniteScroll
                                                className="infiniteScroll"
                                                dataLength={data.results.length}
                                                next={() => this.props.getMorePics(data.next)}
                                                hasMore={data.next !== null}
                                                scrollableTarget="cardsBox"
                                                loader={<></>}
                                            >
                                                <Masonry
                                                    className="masonryContainer"
                                                    options={masonryOptions}
                                                >
                                                    {data.results.length > 0 &&
                                                        data.results.map((item, key) => (
                                                            <CardComponent
                                                                emp={false}
                                                                bookmarkUrl={"pic"}
                                                                parent={"edificios-em-projecto"}
                                                                mouseOverCallBack={() =>
                                                                    this.onItemMouseOver(item)
                                                                }
                                                                key={key}
                                                                position={key}
                                                                item={item}
                                                                cardSelected={this.state.cardSelected}
                                                                setBookmarFunc={(pos, status) =>
                                                                    this.props.setBookMark(pos, status)
                                                                }
                                                            />
                                                        ))}
                                                </Masonry>
                                            </InfiniteScroll>
                                        ) : settings.chartViewActive === "charts" ? (
                                            <div className="picsCharts">
                                                {charts.areaChart !== undefined && (
                                                    <Charts
                                                        stateSelector={"pics"}
                                                        dataChart={{
                                                            chart: {
                                                                type: "column",
                                                            },
                                                            title: {
                                                                text: "",
                                                            },
                                                            legend: {
                                                                enabled: false,
                                                            },
                                                            xAxis: {
                                                                title: {
                                                                    text: "",
                                                                },
                                                                categories: charts.areaChart.categories,
                                                            },
                                                            yAxis: {
                                                                title: {
                                                                    text: "",
                                                                },
                                                            },
                                                            series: [{data: charts.areaChart.data}],
                                                            plotOptions: {
                                                                series: {
                                                                    color: "#116DB7",
                                                                    point: {},
                                                                },
                                                            },
                                                        }}
                                                        tipoChar={"AREA"}
                                                        active={charts.areaChart.activeData}
                                                    />
                                                )}
                                                {charts.fogosChart !== undefined && (
                                                    <Charts
                                                        stateSelector={"pics"}
                                                        dataChart={{
                                                            chart: {
                                                                type: "column",
                                                            },
                                                            title: {
                                                                text: "",
                                                            },
                                                            legend: {
                                                                enabled: false,
                                                            },
                                                            xAxis: {
                                                                title: {
                                                                    text: "",
                                                                },
                                                                categories: charts.fogosChart.categories,
                                                            },
                                                            yAxis: {
                                                                title: {
                                                                    text: "",
                                                                },
                                                            },
                                                            series: [{data: charts.fogosChart.data}],
                                                            plotOptions: {
                                                                series: {
                                                                    color: "#116DB7",
                                                                    point: {},
                                                                },
                                                            },
                                                        }}
                                                        tipoChar={"Nº FOGOS"}
                                                        active={charts.fogosChart.activeData}
                                                        p
                                                    />
                                                )}
                                                {charts.tpyChart !== undefined && (
                                                    <Charts
                                                        stateSelector={"pics"}
                                                        dataChart={{
                                                            chart: {
                                                                type: "column",
                                                            },
                                                            title: {
                                                                text: "",
                                                            },
                                                            legend: {
                                                                enabled: false,
                                                            },
                                                            xAxis: {
                                                                title: {
                                                                    text: "",
                                                                },
                                                                categories: charts.tpyChart.categories,
                                                            },
                                                            yAxis: {
                                                                title: {
                                                                    text: "",
                                                                },
                                                            },
                                                            series: [{data: charts.tpyChart.data}],
                                                            plotOptions: {
                                                                series: {
                                                                    color: "#116DB7",
                                                                    point: {},
                                                                },
                                                            },
                                                        }}
                                                        tipoChar={"Nº TOTAL POR ANO"}
                                                    />
                                                )}
                                            </div>
                                        ) : (
                                            <div className="listWrapper">
                                                <List
                                                    sortColumns={[]}
                                                    parent={"edificios-em-projecto"}
                                                    count={data.count}
                                                    items={data.results}
                                                    getData={() => this.props.getMorePics(data.next)}
                                                    columnBands={columnBandsPics}
                                                    columns={columnsPics}
                                                    nextUrl={data.next}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </SplitterLayout>
                        </SplitterLayout>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        pics: state.pics,
        auth: state.auth,
        loading: state.process,
        map: state.map.map,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        filterPics: (params) => dispatch(filterPics(params)),
        getPics: () => dispatch(getPics()),
        getFilters: () => dispatch(getFilters()),
        setFilterParams: (params) => dispatch(setFilterParams(params)),
        getMorePics: (next) => dispatch(getMorePics(next)),
        setBboxSelect: (selected) => dispatch(setBboxSelect(selected)),
        getDataChart: (values, categories, params) =>
            dispatch(getDataChart(values, categories, params)),
        setChartViewActive: (status) => dispatch(setChartViewActive(status)),
        setSettings: (setting) => dispatch(setSettings(setting)),
        getSearchFields: () => dispatch(getSearchFields()),
        setSearchValue: (searchvalues) => dispatch(setSearchValue(searchvalues)),
        getDataChartTPY: (params) => dispatch(getDataChartTPY(params)),
        setMapRef: (ref) => dispatch(setMapRef(ref)),
        setBookMark: (pos, status) => dispatch(setBookMark(pos, status)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PicContainer);

const HistoDummy = {
    selection: new Set(),
    visibleCategories: 8,
    displayField: "name",
    valueField: "id",
    countField: "count",
    clearText: "Clear Selection",
    emptySelectedText: "Todos",
    showClearSection: true,
    showEmptyMessage: true,
    loadingMask: true,
    moreFilters: false,
};

const masonryOptions = {
    transitionDuration: "0.5s",
    itemSelector: ".card",
    columnWidth: 300,
    gutter: 10,
    fitWidth: true,
    originLeft: true,
};
