import { SvgIcon } from "@material-ui/core";
import React from "react";

const HouseIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M19,9.3V4h-3v2.6L12,3L2,12h3v8h5v-6h4v6h5v-8h3L19,9.3z M10,10c0-1.1,0.9-2,2-2s2,0.9,2,2H10z" />
  </SvgIcon>
);

export default HouseIcon;
