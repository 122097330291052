import * as types from "../constants";

/**
 * Set zoom on state
 */
export const setZomm = (zoomObject) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_MAP_ZOOM,
            payload: zoomObject
        })
    }
}

/**
 * Set map reference on state
 */
export const setMapRef = (ref) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_MAP_REF,
            payload: ref
        })
    }
}
