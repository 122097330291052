import React from "react";
// LEAFLET
import {
  Map,
  TileLayer,
  Marker,
  LayersControl,
} from "react-leaflet";
import L from "leaflet";
import MapMarkerIcon from "../../assets/images/marker.png";
const {BaseLayer} = LayersControl;

const Point = ({ point }) => {
  const customIcon = new L.Icon({
    iconUrl: MapMarkerIcon,
  });

  return (
    <Map center={point} zoom={17}>
      <LayersControl position="topleft">
        <BaseLayer name="Google" checked>
          <TileLayer
            attribution="Google"
            url="http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
            subdomains={["mt0", "mt1", "mt2", "mt3"]}
          />
        </BaseLayer>

        <BaseLayer name="Satellite">
          <TileLayer
            url={`https://api.tiles.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}${
              L.Browser.retina ? "@2x.png" : ".png"
            }?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw`}
          />
        </BaseLayer>
      </LayersControl>
      <Marker position={point} icon={customIcon} />
    </Map>
  );
};

export default Point;
