import * as types from "../constants";

/**
 * initialStateBookmarks interface
 */
export const initialStateBookmarks = {
    list: [], count: 0, next: null
};

/**
 * Receives state and action with function type and payload
 */
export const bookmarksReducer = (state = initialStateBookmarks, action) => {
    switch (action.type) {
        case types.GET_FAVORITES:
            return {
                ...state, list: action.payload.list, count: action.payload.count, next: action.payload.next
            };
        default:
            return {
                ...state,
            };
    }
};
