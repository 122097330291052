import React, {Component} from "react";
// COMPONENTS
import {
    Button,
    Form,
} from "react-bootstrap";
import Categories from "../components/Categories/Categories";
import SplitterLayout from "react-splitter-layout";
import MapComponent from "../components/Map/MapComponent";
import LoadingMask from "../components/LoadingMask";
import InfiniteScroll from "react-infinite-scroll-component";
import Charts from "../components/Charts/Charts";
import Masonry from "react-masonry-component";
import CardComponent from "../components/Card";
import {layerGroup, CircleMarker} from "leaflet";
import List from "../components/List";
import Select from "react-select";
import swal from "@sweetalert/with-react";
// ICONS
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChartBar,
    faChevronCircleDown,
    faChevronCircleUp,
    faFilter, faList, faThLarge,
} from "@fortawesome/free-solid-svg-icons";
import {ExportApi} from "../redux/configs/api";
import clsx from "clsx";
import {columnBandsLic, columnsLic} from "../configs/configs";
// REDUX
import {connect} from "react-redux";
import {
    fetchConcelhoLicencas,
    fetchMoreData,
    fetchData,
    fetchDestinoLicencas,
    fetchTipoLicencas,
    setFilterParamsLicencas,
    setSettingsLic,
    getDataChart,
    setSearchValueLic,
    setChartActive,
    setChartViewActive,
    setBboxSelectLic,
    getSearchFields,
    getDataChartTPY,
    setBookMarkLic,
} from "../redux/actions/licencas";
import {setZomm} from "../redux/actions/map";
import {DateFilter} from "../components/Filters/DateFilter";
import {AreasFilter} from "../components/Filters/AreaFilter";
import Toolbar from "../components/Toolbar";
import {SmallToolbar} from "../components/SmallToolbar/SmallToolbar";

class LicencaContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            moreFilters: true,
            regConDefaultOptions: [],
            origin: null,
            bboxSelect: null,
            show_data_emissao_after: null,
            show_data_emissao_before: null,
            mainView: "cards",
            markers: [],
            rightView: "cards",
            mark: layerGroup(),
            isClean: false,
            cardSelected: null,
            show: false,
            target: null,
            updateWidth: false
        };
    }


    componentDidMount() {
        if (this.props.licencas.data.results.length === 0) {
            this.props.fetchData({});
            this.props.fetchTipoLicencas();
            this.props.fetchDestinoLicencas();
            this.props.fetchConcelhoLicencas();
            this.props.getSearchFields();
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.licencas.params !== this.props.licencas.params) {
            this.props.fetchData(this.props.licencas.params);
            this.updateDataChart();
            this.setState({isClean: false});
        }

        if (!this.state.updateWidth) {
            let wrapper = document.querySelector(".map .layout-pane:first-child");
            wrapper.style.width = "30%";
            window.dispatchEvent(new Event("resize"));
            this.setState({updateWidth: true});
        }

    }

    componentWillUnmount() {
        const bboxCome =  window.leafletMap.getBounds();
        const bbox = [
            bboxCome.getNorthEast().lng,
            bboxCome.getNorthEast().lat,
            bboxCome.getSouthWest().lng,
            bboxCome.getSouthWest().lat,
        ];
        this.props.setBboxSelectLic({
            ...this.props.licencas.settings.bbox_selected,
            bbox: bbox,
        });
    }

    handlerChange(e) {
        if (e === null || e.length === 0) {
            this.props.setFilterParamsLicencas({
                concelho: null,
            });
            this.props.setBboxSelectLic(null);
        } else {
            this.props.setFilterParamsLicencas({
                concelho: e.value,
            });
            this.props.setBboxSelectLic(e);
        }
    }

    handlerAreasFilter(e, clear = false) {
        if (!clear) {
            this.props.setFilterParamsLicencas({
                [e.target.name]: e.target.value !== "" ? parseInt(e.target.value) : "",
            });
        } else {
            this.props.setFilterParamsLicencas({
                area_construcao_min: "",
                area_construcao_max: "",
            });
        }
    }

    handlerCleanFilters() {
        this.setState({
            bboxSelect: null,
            show_alvara_data_after: null,
            show_alvara_data_before: null,
            isClean: true,
        });
        this.props.setBboxSelectLic(null);
        this.props.setSettingsLic({});
        this.props.setFilterParamsLicencas({});
        this.clearShearField();
    }

    exportData() {
        const count = this.props.licencas.data.count;
        let msg = "Extração limitada a 250 registos. Deseja os 250 mais recentes?";
        if (count < 250) {
            msg = `Deseja extrair os ${count} registos?`;
        }
        swal({
            text: msg,
            buttons: {
                cancel: "Não",
                catch: {
                    text: "Sim",
                    value: true,
                },
            },
        }).then((value) => {
            if (value) {
                ExportApi.get("licenca.xlsx/", {
                    params: this.props.licencas.params,
                }).then((response) => {
                    const url = window.URL.createObjectURL(
                        new Blob([response.data], {type: "application/vnd.ms-excel"})
                    );
                    var link = document.createElement("a");
                    link.href = url;
                    const date = new Intl.DateTimeFormat("pt", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                    })
                        .format(new Date())
                        .replaceAll("/", "_");
                    link.download = `Edf_Obra_Export_${date}.xlsx`;
                    document.body.appendChild(link); // we need to append the element to the dom -> otherwise it will not work in firefox
                    link.click();
                    link.remove(); //afterwards we remove the element again
                });
            }
        });
    }

    onDragEnd() {
        window.dispatchEvent(new Event("resize"));
        let tb = document.querySelector(".topBarWrapper");
        let rp = document.querySelector(".rightPanelActions");
        let st = document.querySelector(".small-wrapper");
        let cardsWrapper = document.querySelector(".map .layout-pane-primary");
        if (cardsWrapper.offsetWidth < 584) {
            tb.style.display = "none";
            rp.style.display = "none";
            st.classList.add('show');
        } else {
            tb.style.display = "block";
            rp.style.display = "flex";
            st.classList.remove('show');
        }
    }

    setNewMarker(incomeMarker) {
        let mList = this.state.markers,
            exist = mList.includes(incomeMarker);
        if (!exist) {
            mList.push(incomeMarker);
            this.setState({markers: mList});
        }
    }

    updateDataChart() {
        setTimeout(() => {
            const {charts, params, settings} = this.props.licencas;
            if (settings.chartViewActive) {
                this.props.getDataChart(
                    "area_construcao",
                    charts.areaChart.activeData,
                    params
                );
                this.props.getDataChart(
                    "n_fogos_total",
                    charts.fogosChart.activeData,
                    params
                );
                this.props.getDataChartTPY(params);
            }
        }, 250);
    }

    onItemMouseOver(item) {
        window.leafletMap.removeLayer(this.state.mark);
        this.state.mark.clearLayers();
        this.state.mark
            .addLayer(
                new CircleMarker([item.gps.coordinates[1], item.gps.coordinates[0]], {
                    id: item.id,
                    fillOpacity: 1,
                    stroke: true,
                    color: "white",
                    radius: 15,
                    fillColor:
                        item.tipo_id === 1
                            ? "#246db7"
                            : item.tipo_id === 2
                                ? "#f9ba04"
                                : "#7b7c7b",
                })
            )
            .addTo(window.leafletMap);
    }

    filterBySearchFields() {
        this.props.setFilterParamsLicencas({
            ...this.props.licencas.searchFilters,
        });
    }

    clearShearField() {
        let fields = {...this.props.licencas.searchFilters};
        for (let f in fields) {
            fields[f] = "";
        }
        this.props.setSearchValueLic(fields);
        this.props.setFilterParamsLicencas({...fields});
    }

    render() {
        const {filters, params, data, charts, settings, searchFilters} =
            this.props.licencas;
        const {loading} = this.props.loading;
        const {user} = this.props.auth;
        const {isClean, show, mainView} = this.state;

        return (
            <section className="main-content">
                <div className="mapCardsActions">
                    <div className="mapCardsWrapper">
                        <SplitterLayout
                            primaryIndex={1}
                            percentage
                            customClassName={clsx("splitterClass filters-splitter")}
                            onDragEnd={this.onDragEnd}
                        >
                            <div className="filterWrapper">
                                <div className="list">
                                    <Button
                                        className="moreFiltersBtn"
                                        onClick={() =>
                                            this.setState({moreFilters: !this.state.moreFilters})
                                        }
                                    >
                                        {this.state.moreFilters ? (
                                            <>
                                                <FontAwesomeIcon icon={faChevronCircleUp}/> FILTROS
                                            </>
                                        ) : (
                                            <>
                                                <FontAwesomeIcon icon={faChevronCircleDown}/> FILTROS
                                            </>
                                        )}
                                    </Button>
                                    <Button
                                        className="cleanFilters"
                                        onClick={() => this.handlerCleanFilters()}
                                    >
                                        <FontAwesomeIcon icon={faFilter}/> LIMPAR
                                    </Button>
                                    <section
                                        className={
                                            this.state.moreFilters ? "show-filters" : "toggle-filters"
                                        }
                                    >
                                        <Form.Group>
                                            <label className="label">Concelho</label>
                                            <Select
                                                defaultValue={settings.bbox_selected}
                                                isClearable={true}
                                                name="concelho"
                                                options={filters.concelhos}
                                                onChange={(e) => this.handlerChange(e)}
                                                className="react-select"
                                                classNamePrefix="react-select"
                                                value={settings.bbox_selected}
                                            />
                                        </Form.Group>
                                        {user.pic !== undefined && (
                                            <DateFilter
                                                min_year={user.licenca.min_year}
                                                max_year={user.licenca.max_year}
                                                filterNameBefore="alvara_data_before"
                                                filterNameAfter="alvara_data_after"
                                                selectedFilterNameBefore={
                                                    settings.show_alvara_data_before
                                                }
                                                selectedFilterNameAfter={
                                                    settings.show_alvara_data_after
                                                }
                                                setFilterParams={(_params) =>
                                                    this.props.setFilterParamsLicencas(_params)
                                                }
                                                setSettings={(_settings) =>
                                                    this.props.setSettingsLic(_settings)
                                                }
                                                title="Data Alvara"
                                            />
                                        )}
                                        <AreasFilter
                                            title={"Área de Construção (m2)"}
                                            params={params}
                                            filter_min={"area_construcao_min"}
                                            filter_max={"area_construcao_max"}
                                            cleanText={"Limpar áreas"}
                                            handlerValuesFilter={(e, bool) =>
                                                this.handlerAreasFilter(e, bool)
                                            }
                                        />
                                    </section>
                                    <hr/>
                                    <Categories
                                        params={params}
                                        key={"tipo_0"}
                                        {...HistoDummy}
                                        data={filters.tipo}
                                        title={"Tipo De Obra"}
                                        filter={"tipo"}
                                        isPics={false}
                                        isClean={isClean}
                                    />
                                    <hr/>
                                    <Categories
                                        params={params}
                                        key={"destino_0"}
                                        {...HistoDummy}
                                        data={filters.destino}
                                        title={"Destino"}
                                        filter={"destino"}
                                        isPics={false}
                                        isClean={isClean}
                                    />
                                    <hr/>
                                    <Form.Group className={"information text-muted"}>
                                        <strong>Última atualização de dados:</strong>
                                        <br/>
                                        {user.licenca_versao}
                                        <br/>
                                        <strong>Dados inseridos:</strong>
                                        <br/>
                                        {user.licenca_dados}
                                    </Form.Group>
                                </div>
                            </div>
                            <SplitterLayout
                                primaryIndex={1}
                                percentage
                                secondaryMinSize={0}
                                primaryMinSize={0}
                                onDragEnd={this.onDragEnd}
                                customClassName={clsx(
                                    "splitterClass map",
                                    this.state.mainView !== "cards" ? "hide" : "show"
                                )}>
                                <div className="mapWrapper">
                                    <MapComponent
                                        bboxInit={null}
                                        params={params}
                                        layers={"ci:licenca"}
                                        items={data.results}
                                        callBack={(incomeMarker) => this.setNewMarker(incomeMarker)}
                                        filter={(param) => this.props.fetchData(param)}
                                        setParams={(param) =>
                                            this.props.setFilterParamsLicencas(param)
                                        }
                                        dataType="lic"
                                        origin="edificios-em-obra"
                                    />
                                </div>
                                <div className="gridList">
                                    <SmallToolbar
                                        exportData={() => this.exportData()}
                                        changeView={(view) => this.props.setChartViewActive(view)}
                                        updateDataChart={() => this.updateDataChart()}
                                        chartViewActive={settings.chartViewActive}
                                        count={data.count}
                                    />
                                    <Toolbar
                                        loading={loading}
                                        count={data.count}
                                        show={show}
                                        mainView={mainView}
                                        setState={(e) => this.setState(e)}
                                        exportData={() => this.exportData()}
                                        search_filters={filters.search_filters}
                                        searchFilters={searchFilters}
                                        setSearchValue={(e) => this.props.setSearchValueLic(e)}
                                        filterBySearchFields={() => this.filterBySearchFields()}
                                        clearShearField={() => this.clearShearField()}
                                    />
                                    <div className="rightPanelActions">
                                        <section className="changeView">
                                            <Button
                                                onClick={() => this.props.setChartViewActive("list")}
                                                className={
                                                    settings.chartViewActive === "list" ? "active" : ""
                                                }
                                            >
                                                <FontAwesomeIcon icon={faList}/>
                                            </Button>

                                            <Button
                                                onClick={() => this.props.setChartViewActive("fichas")}
                                                className={
                                                    settings.chartViewActive === "fichas" ? "active" : ""
                                                }
                                            >
                                                <FontAwesomeIcon icon={faThLarge}/>
                                            </Button>

                                            <Button
                                                onClick={() => {
                                                    this.props.setChartViewActive("charts");
                                                    this.updateDataChart();
                                                }}
                                                className={
                                                    settings.chartViewActive === "charts" ? "active" : ""
                                                }
                                            >
                                                <FontAwesomeIcon icon={faChartBar}/>
                                            </Button>

                                        </section>
                                        <section className="counts">
                                            <Button disabled={true}>
                                                {`${
                                                    data.count === undefined ? 0 : data.count
                                                }  RESULTADOS`}
                                            </Button>
                                        </section>
                                    </div>
                                    <div id="cardsBox">
                                        {loading && settings.chartViewActive !== "" ? (
                                            <LoadingMask/>
                                        ) : null}
                                        {settings.chartViewActive === "fichas" ? (
                                            <InfiniteScroll
                                                className="infiniteScroll"
                                                dataLength={data.results.length}
                                                next={() => this.props.fetchMoreData(data.next)}
                                                hasMore={data.next !== null}
                                                scrollableTarget="cardsBox"
                                                loader={<></>}
                                            >
                                                <Masonry
                                                    className="masonryContainer"
                                                    options={masonryOptions}
                                                >
                                                    {data.results.length > 0 &&
                                                        data.results.map((item, key) => (
                                                            <CardComponent
                                                                emp={false}
                                                                bookmarkUrl={"licenca"}
                                                                parent={"edificios-em-obra"}
                                                                mouseOverCallBack={() =>
                                                                    this.onItemMouseOver(item)
                                                                }
                                                                key={key}
                                                                position={key}
                                                                item={item}
                                                                cardSelected={this.state.cardSelected}
                                                                setBookmarFunc={(pos, status) =>
                                                                    this.props.setBookMarkLic(pos, status)
                                                                }
                                                            />
                                                        ))}
                                                </Masonry>
                                            </InfiniteScroll>
                                        ) : settings.chartViewActive === "charts" ? (
                                            <div className="picsCharts">
                                                {charts.areaChart !== undefined && (
                                                    <Charts
                                                        stateSelector={"licencas"}
                                                        dataChart={{
                                                            chart: {
                                                                type: "column",
                                                            },
                                                            title: {
                                                                text: "",
                                                            },
                                                            legend: {
                                                                enabled: false,
                                                            },
                                                            xAxis: {
                                                                title: {
                                                                    text: "",
                                                                },
                                                                categories: charts.areaChart.categories,
                                                            },
                                                            yAxis: {
                                                                title: {
                                                                    text: "",
                                                                },
                                                            },
                                                            series: [{data: charts.areaChart.data}],
                                                            plotOptions: {
                                                                series: {
                                                                    color: "#116DB7",
                                                                    point: {},
                                                                },
                                                            },
                                                        }}
                                                        tipoChar={"AREA"}
                                                        active={charts.areaChart.activeData}
                                                    />
                                                )}
                                                {charts.fogosChart !== undefined && (
                                                    <Charts
                                                        stateSelector={"licencas"}
                                                        dataChart={{
                                                            chart: {
                                                                type: "column",
                                                            },
                                                            title: {
                                                                text: "",
                                                            },
                                                            legend: {
                                                                enabled: false,
                                                            },
                                                            xAxis: {
                                                                title: {
                                                                    text: "",
                                                                },
                                                                categories: charts.fogosChart.categories,
                                                            },
                                                            yAxis: {
                                                                title: {
                                                                    text: "",
                                                                },
                                                            },
                                                            series: [{data: charts.fogosChart.data}],
                                                            plotOptions: {
                                                                series: {
                                                                    color: "#116DB7",
                                                                    point: {},
                                                                },
                                                            },
                                                        }}
                                                        tipoChar={"Nº FOGOS"}
                                                        active={charts.fogosChart.activeData}
                                                    />
                                                )}
                                                {charts.tpyChart !== undefined && (
                                                    <Charts
                                                        stateSelector={"licencas"}
                                                        dataChart={{
                                                            chart: {
                                                                type: "column",
                                                            },
                                                            title: {
                                                                text: "",
                                                            },
                                                            legend: {
                                                                enabled: false,
                                                            },
                                                            xAxis: {
                                                                title: {
                                                                    text: "",
                                                                },
                                                                categories: charts.tpyChart.categories,
                                                            },
                                                            yAxis: {
                                                                title: {
                                                                    text: "",
                                                                },
                                                            },
                                                            series: [{data: charts.tpyChart.data}],
                                                            plotOptions: {
                                                                series: {
                                                                    color: "#116DB7",
                                                                    point: {},
                                                                },
                                                            },
                                                        }}
                                                        tipoChar={"Nº TOTAL POR ANO"}
                                                    />
                                                )}
                                            </div>
                                        ) : (
                                            <div className="listWrapper">
                                                <List
                                                    parent={"edificios-em-obra"}
                                                    count={data.count}
                                                    items={data.results}
                                                    getData={() => this.props.fetchMoreData(data.next)}
                                                    columnBands={columnBandsLic}
                                                    columns={columnsLic}
                                                    nextUrl={data.next}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </SplitterLayout>
                        </SplitterLayout>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        licencas: state.licencas,
        auth: state.auth,
        loading: state.process,
        map: state.map.map,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchData: (params) => dispatch(fetchData(params)),
        fetchTipoLicencas: () => dispatch(fetchTipoLicencas()),
        fetchDestinoLicencas: () => dispatch(fetchDestinoLicencas()),
        fetchConcelhoLicencas: () => dispatch(fetchConcelhoLicencas()),
        setFilterParamsLicencas: (params) =>
            dispatch(setFilterParamsLicencas(params)),
        setSettingsLic: (setting) => dispatch(setSettingsLic(setting)),
        getDataChart: (values, categories, params) =>
            dispatch(getDataChart(values, categories, params)),
        setSearchValueLic: (searchvalues) =>
            dispatch(setSearchValueLic(searchvalues)),
        setChartActive: (chart, activeData) =>
            dispatch(setChartActive(chart, activeData)),
        setChartViewActive: (status) => dispatch(setChartViewActive(status)),
        fetchMoreData: (next) => dispatch(fetchMoreData(next)),
        setBboxSelectLic: (selected) => dispatch(setBboxSelectLic(selected)),
        getSearchFields: () => dispatch(getSearchFields()),
        setZomm: (zoomObject) => dispatch(setZomm(zoomObject)),
        getDataChartTPY: (params) => dispatch(getDataChartTPY(params)),
        setBookMarkLic: (pos, status) => dispatch(setBookMarkLic(pos, status)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LicencaContainer);

const HistoDummy = {
    selection: new Set(),
    visibleCategories: 8,
    displayField: "name",
    valueField: "id",
    countField: "count",
    clearText: "Clear Selection",
    emptySelectedText: "Todos",
    showClearSection: true,
    showEmptyMessage: true,
    loadingMask: true,
    moreFilters: false,
};

const masonryOptions = {
    transitionDuration: "0.5s",
    itemSelector: ".card",
    columnWidth: 300,
    gutter: 10,
    fitWidth: true,
    originLeft: true,
};
