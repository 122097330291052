import {combineReducers} from "redux";
import {siteVariablesReducer} from "./configControllers";
import {bookmarksReducer} from "./bookmarks";
import {licencasReducer} from "./licencas";
import {empreendimentosReducer} from "./empreendimento";
import {PicsReducer} from "./pics";
import {processReducer} from "./process";
import {authReducer} from './auth';
import {mapReducer} from "./map";

/**
 * Export all reducers with a combineReducers function
 */
export default combineReducers({
    siteVariables: siteVariablesReducer,
    bookmarks: bookmarksReducer,
    licencas: licencasReducer,
    empreendimentos: empreendimentosReducer,
    pics: PicsReducer,
    process: processReducer,
    auth: authReducer,
    map: mapReducer
});
