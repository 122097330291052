import * as types from "../constants";

/**
 * initialStatePics interface
 */
export const initialStatePics = {
  data: {
    results: [],
    count: 0,
    next: null,
    previous: null,
  },
  filters: {
    tipo: [],
    destino: [],
    ce: [],
    regiao_concelho: [],
    search_filters: [],
  },
  params: {
    concelho: null,
    regiao: null,
    data_emissao_after: null,
    data_emissao_before: null,
    area_construcao_min: "",
    area_construcao_max: "",
    agentes: false,
    tipo: null,
    destino: null,
    classe_energetica: null,
  },
  settings: {
    chartViewActive: "fichas",
    bbox_selected: null,
    show_data_emissao_after: null,
    show_data_emissao_before: null,
  },
  searchFilters: {},
  charts: {
    fogosChart: {
      categories: [],
      data: [],
      activeData: "destino",
    },
    areaChart: {
      categories: [],
      data: [],
      activeData: "destino",
    },
    tpyChart: {
      categories: [],
      data: [],
    },
  },
  filtersActive: {},
  itemDetail: null,
};

/**
 * Receives state and action with function type and payload
 */
export const PicsReducer = (state = initialStatePics, action) => {
  switch (action.type) {
    case types.GET_PICS:
      return {
        ...state,
        data: action.payload,
      };
    case types.GET_MORE_PICS:
      return {
        ...state,
        data: {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
          results: state.data.results.concat(action.payload.results),
        },
      };
    // filters
    case types.GET_DESTINO_PICS:
      return {
        ...state,
        filters: {
          ...state.filters,
          destino: action.payload,
        },
      };
    case types.GET_TIPO_PICS:
      return {
        ...state,
        filters: {
          ...state.filters,
          tipo: action.payload,
        },
      };
    case types.GET_CE_PICS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ce: action.payload,
        },
      };
    case types.GET_REGIAO_PICS:
      return {
        ...state,
        filters: {
          ...state.filters,
          regiao_concelho: action.payload,
        },
      };
    case types.GET_SEARCH_FIELDS:
      return {
        ...state,
        filters: {
          ...state.filters,
          search_filters: action.payload,
        },
      };
    case types.SET_LEFT_FILTERS_ACTIVE:
      return {
        ...state,
        filtersActive: {
          ...state.filtersActive,
          [action.payload.name]: action.payload.selected,
        },
      };
    case types.SET_FILTER_PARAMS:
      const params = { ...state.params, ...action.payload };
      return {
        ...state,
        params: {
          ...params,
        },
      };
    case types.SET_ACTIVE_FILTERS_PICS:
      return {
        ...state,
        filtersActive: {
          ...state.filtersActive,
          ...action.payload,
        },
      };
    case types.SET_FILTER_PARAMS_SEARCH:
      return {
        ...state,
        searchFilters: {
          ...state.searchFilters,
          ...action.payload,
        },
      };
    // charts
    case types.SET_AREA_CHART:
      let areaCategories = [],
        areaData = [];
      for (let item of action.payload) {
        areaCategories.push(item.category);
        areaData.push(item.value);
      }
      return {
        ...state,
        charts: {
          ...state.charts,
          areaChart: {
            categories: areaCategories,
            data: areaData,
            activeData: state.charts.areaChart.activeData,
            filters: state.charts.areaChart.filters,
          },
        },
      };
    case types.SET_FOGOS_CHART:
      let fogosCategories = [],
        fogosData = [];
      for (let item of action.payload) {
        fogosCategories.push(item.category);
        fogosData.push(item.value);
      }
      return {
        ...state,
        charts: {
          ...state.charts,
          fogosChart: {
            categories: fogosCategories,
            data: fogosData,
            activeData: state.charts.fogosChart.activeData,
          },
        },
      };
    case types.SET_AREA_CHART_ACTIVE_DATA:
      return {
        ...state,
        charts: {
          ...state.charts,
          areaChart: {
            categories: state.charts.areaChart.categories,
            data: state.charts.areaChart.data,
            activeData: action.payload,
          },
        },
      };
    case types.SET_FOGOS_CHART_ACTIVE_DATA:
      return {
        ...state,
        charts: {
          ...state.charts,
          fogosChart: {
            categories: state.charts.fogosChart.categories,
            data: state.charts.fogosChart.data,
            activeData: action.payload,
          },
        },
      };
    case types.SET_TPY_PIC_CHART:
      let tpyCategories = [],
        tpyData = [];
      for (let item of action.payload) {
        tpyCategories.push(item.year);
        tpyData.push(item.count);
      }
      return {
        ...state,
        charts: {
          ...state.charts,
          tpyChart: {
            categories: tpyCategories,
            data: tpyData,
            activeData: state.charts.tpyChart.activeData,
          },
        },
      };
    case types.SET_CHARTS_VIEW_ACTIVE:
      return {
        ...state,
        settings: {
          ...state.settings,
          chartViewActive: action.payload,
        },
      };
    // detail
    case types.GET_PIC_DETAIL:
      return {
        ...state,
        itemDetail: action.payload,
      };
    // bbox for map
    case types.SET_BBOX_SELECTED:
      return {
        ...state,
        settings: {
          ...state.settings,
          bbox_selected: action.payload,
        },
      };
    case types.SET_CONCELHO_SELECTED:
      return {
        ...state,
        concelhoSelected: action.payload,
      };
    case types.SET_PICS_SETTINGS:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload,
        },
      };
    // bookmarks
    case types.SET_BOOKMARK_PIC:
      let data = state.data;
      data.results[action.payload].bookmarked = action.status;
      return {
        ...state,
        data: data,
      };
    default:
      return {
        ...state,
      };
  }
};
