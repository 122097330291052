import React, {Component} from 'react';
import './HomePage.scss'

class HomePage extends Component {
    render() {
        return (
            <div className={'homepage'}>
                <iframe src="/home/" frameBorder={0} title="Home Page"/>
            </div>
        );
    }
}

export default HomePage;
