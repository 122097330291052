export class DataProcess {
    static abbreviateNumber(number) {
        let dictionary = [{
                value: 1E18,
                symbol: 'E'
            }, {
                value: 1E15,
                symbol: 'P'
            }, {
                value: 1E12,
                symbol: 'T'
            }, {
                value: 1E9,
                symbol: 'B'
            }, {
                value: 1E6,
                symbol: 'M',
                factor: 1000000
            }, {
                value: 1E3,
                symbol: 'k',
                factor: 1000
            }],
            i;
        for (i = 0; i < dictionary.length; i++) {
            if (number >= dictionary[i].value) {
                return (number / dictionary[i].value).toFixed(1) + dictionary[i].symbol;
            }
        }
        return number;
    }
}
