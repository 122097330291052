import React from "react";
import Table from "react-bootstrap/Table";

const Apresentacao = ({ detail }) => {
  return (
    <>
      <div className={"news"}>
        <p
          className={"news-title"}
          dangerouslySetInnerHTML={{ __html: detail.noticia.titulo }}
        />
        <section
          className={"news-body"}
          dangerouslySetInnerHTML={{ __html: detail.noticia.corpo }}
        />
      </div>
      <div className="info-comer">
        <h3 className="tableTitle">Dados Gerais</h3>
        <Table striped bordered hover>
          <tbody>
            <tr>
              <td className="tableLeft">Nome do empreendimento:</td>
              <td className="tableRight">{detail.dados_gerais.nome}</td>
            </tr>
            {detail.agentes.filter((agente) => agente.tipo_id === 2).length >
              0 && (
              <tr>
                <td className="tableLeft">Promotor:</td>
                <td className="tableRight">
                  {
                    detail.agentes.filter((agente) => agente.tipo_id === 2)[0]
                      .nome
                  }
                </td>
              </tr>
            )}
            <tr>
              <td className="tableLeft">Fogos em oferta:</td>
              <td className="tableRight">
                {detail.dados_gerais.disponibilidade}
              </td>
            </tr>
            <tr>
              <td className="tableLeft">Sector:</td>
              <td className="tableRight">{detail.dados_gerais.sector}</td>
            </tr>
            {detail.dados_fisicos.valor_m2 !== null && (
              <tr>
                <td className="tableLeft">Valor médio oferta / m2:</td>
                <td className="tableRight">
                  {new Intl.NumberFormat("de-DE", {
                    style: "currency",
                    currency: "EUR",
                    minimumFractionDigits: 0,
                    useGrouping: true,
                  }).format(detail.dados_fisicos.valor_m2)}
                </td>
              </tr>
            )}
            <tr>
              <td className="tableLeft">Número Frações:</td>
              <td className="tableRight">{detail.dados_fisicos.nr_fracoes}</td>
            </tr>
            <tr>
              <td className="tableLeft">Concelho</td>
              <td className="tableRight">{detail.localizacao.concelho}</td>
            </tr>
            <tr>
              <td className="tableLeft">Freguesia</td>
              <td className="tableRight">{detail.localizacao.freguesia}</td>
            </tr>
            <tr>
              <td className="tableLeft">Data última actualização:</td>
              <td className="tableRight">
                {new Intl.DateTimeFormat("pt-PT", {
                  year: "numeric",
                  month: "short",
                  day: "2-digit",
                }).format(new Date(detail.interno.ultima_actualizacao))}
              </td>
            </tr>
          </tbody>
        </Table>

        <div className="logo-agentes">
          {detail.agentes.filter(
            (agente) => agente.tipo_id === 2 && agente.logo).length > 0 && (
            <div>
              <h3 className="tableTitle">Promotor imobiliário</h3>
              <div className="row">
                <div className={"col-12"}>
                  {detail.agentes.map((agen, key) => {
                    if (agen.tipo_id === 2 && agen.logo) {
                      return (
                        <a
                          className={"logos"}
                          key={key}
                          href={agen.website}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={
                              process.env.NODE_ENV === "development"
                                ? "https://pipelineimobiliario.pt/" + agen.logo
                                : agen.logo
                            }
                            alt={agen.nome}
                          />
                        </a>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
              <hr />
            </div>
          )}
          {detail.agentes.filter((agente) => agente.tipo_id === 3 && agente.logo).length >
            0 && (
            <div>
              <h3 className="tableTitle">Mediador</h3>
              <div className="row">
                <div className={"col-12"}>
                  {detail.agentes.map((agen, key) => {
                    if (agen.tipo_id === 3 && agen.logo) {
                      return (
                        <a
                          className={"logos"}
                          key={key}
                          href={agen.website}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={
                              process.env.NODE_ENV === "development"
                                ? "https://pipelineimobiliario.pt/" + agen.logo
                                : agen.logo
                            }
                            alt={agen.nome}
                          />
                        </a>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Apresentacao;
