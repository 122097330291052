import React, { Component } from "react";
import {
  LayersControl,
  Map,
  TileLayer,
  WMSTileLayer,
  GeoJSON,
} from "react-leaflet";
import "./MapLegendControl.scss";
import { connect } from "react-redux";
import { LatLngBounds, Util, CircleMarker, Browser } from "leaflet";
import processGeoserverUrl from "./processGeoserverUrl";
import { setMapRef, setZomm } from "../../redux/actions/map";
import { withRouter } from "react-router-dom";

const { BaseLayer } = LayersControl;
const BASE_URL = "https://pipelineimobiliario.pt/geoserver/ci/wms?tiled=true";
var timer = 0;

class MapComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      geoUrl: "",
      mapFilter: false,
      bbox: null,
      center: [39.4944372, -7.9066871],
      zoom: 7,
      loadFinish: false,
      filterMapActive: false,
      features: false,
      edificios: [],
    };
  }

  componentDidMount() {
    this.getGeoServerData();
    setTimeout(() => {
      this.refs.map.leafletElement.invalidateSize();
      window.leafletMap = this.refs.map.leafletElement;
      if (this.props.bboxInit !== null) {
        this.refs.map.leafletElement.fitBounds(
          new LatLngBounds([
            [this.props.bboxInit[1], this.props.bboxInit[0]],
            [this.props.bboxInit[3], this.props.bboxInit[2]],
          ])
        );
      } else {
        if (
          this.props.map[this.props.dataType] !== null &&
          this.props.map[this.props.dataType] !== undefined
        ) {
          this.refs.map.leafletElement.fitBounds(
            new LatLngBounds([
              [
                this.props.map[this.props.dataType][1],
                this.props.map[this.props.dataType][0],
              ],
              [
                this.props.map[this.props.dataType][3],
                this.props.map[this.props.dataType][2],
              ],
            ])
          );
        }
      }
      this.setState({ loadFinish: true });
    }, 250);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.params !== this.props.params) {
      this.getGeoServerData();
    }
    if (!this.state.filterMapActive) {
      if (this.props.dataType === "lic") {
        if (this.props.concelhoLic !== null) {
          this.refs.map.leafletElement.fitBounds(
            new LatLngBounds([
              [this.props.concelhoLic.bbox[1], this.props.concelhoLic.bbox[0]],
              [this.props.concelhoLic.bbox[3], this.props.concelhoLic.bbox[2]],
            ])
          );
        }
      }
      if (this.props.dataType === "pic") {
        if (this.props.regiaoPic !== null) {
          this.refs.map.leafletElement.fitBounds(
            new LatLngBounds([
              [this.props.regiaoPic.bbox[1], this.props.regiaoPic.bbox[0]],
              [this.props.regiaoPic.bbox[3], this.props.regiaoPic.bbox[2]],
            ])
          );
        }
      }
      if (this.props.dataType === "emp") {
        if (this.props.regCon !== null) {
          this.refs.map.leafletElement.fitBounds(
            new LatLngBounds([
              [this.props.regCon.bbox[1], this.props.regCon.bbox[0]],
              [this.props.regCon.bbox[3], this.props.regCon.bbox[2]],
            ])
          );
        }
      }
    }
  }

  getGeoServerData() {
    const geoParams = processGeoserverUrl(this.props.params);

    if (geoParams !== "") {
      this.setState({
        geoUrl: `${BASE_URL}&CQL_FILTER=${geoParams}`,
      });
    } else {
      this.setState({ geoUrl: BASE_URL });
    }
  }

  getFeatureInfo(e) {
    let WMSURL;
    const geoParams = processGeoserverUrl(this.props.params);
    if (process.env.NODE_ENV === "development") {
      WMSURL =
        "https://pipelineimobiliario.pt/geoserver/ci/wms?FEATURE_COUNT=11";
    } else {
      WMSURL = "geoserver/ci/wms?FEATURE_COUNT=11";
    }
    if (geoParams !== "") {
      WMSURL = `${WMSURL}&CQL_FILTER=${geoParams}`;
    }
    var size = this.refs.map.leafletElement.getSize(),
      params = {
        request: "GetFeatureInfo",
        service: "WMS",
        srs: "EPSG:4326",
        transparent: true,
        version: "1.1.1",
        format: "image/png",
        bbox: this.refs.map.leafletElement.getBounds().toBBoxString(),
        height: size.y,
        width: size.x,
        layers: this.props.layers,
        query_layers: this.props.layers,
        info_format: "application/json",
        x: Math.round(e.containerPoint.x),
        y: Math.round(e.containerPoint.y),
      };

    const URL = WMSURL + Util.getParamString(params, WMSURL, true);
    fetch(URL)
      .then((response) => response.json())
      .then((data) => {
        if (data.features.length === 11) {
          if (this.refs.map.leafletElement.getZoom() >= 10) {
            this.refs.map.leafletElement.setView(
              e.latlng,
              this.refs.map.leafletElement.getZoom() + 2
            );
          } else {
            this.refs.map.leafletElement.setView(e.latlng, 10);
          }
        } else {
          if (data.features.length > 0) {
            let tpl = [];
            for (let edf of data.features) {
              tpl.push(
                "<a class='btn btn-block btn-primary' style='color: white' href='/" +
                  this.props.origin +
                  "/" +
                  edf.properties.id +
                  "' target='_blank'>" +
                  edf.properties.id +
                  "</a>"
              );
            }
            let mk = new CircleMarker(e.latlng, {
              stroke: false,
              fillOpacity: 0,
            }).bindPopup(tpl.join(""));
            this.refs.map.leafletElement.addLayer(mk);
            mk.openPopup();
          }
        }
      });
  }

  mapFilter() {
    clearTimeout(timer);
    timer = setTimeout(
      () => {
        const bboxCome = this.refs.map.leafletElement.getBounds();
        const bbox = [
          bboxCome.getNorthEast().lng,
          bboxCome.getNorthEast().lat,
          bboxCome.getSouthWest().lng,
          bboxCome.getSouthWest().lat,
        ];
        this.props.setParams({ in_bbox: bbox.join(",") });
        let myparams = { ...this.props.params };
        myparams["in_bbox"] = bbox.join(",");
        this.props.filter(myparams);
      },
      1000,
      this
    );
  }

  clearMapFilter() {
    this.props.setParams({ in_bbox: null });
    let myparams = { ...this.props.params };
    myparams["in_bbox"] = null;
    this.props.filter(myparams);
  }

  render() {
    const { layers } = this.props;
    return (
      <Map
        ref="map"
        center={this.state.center}
        zoom={this.state.zoom}
        onZoomEnd={() => {
          if (this.state.filterMapActive) {
            this.mapFilter();
          }
        }}
        onMoveEnd={() => {
          if (this.state.filterMapActive) {
            this.mapFilter();
          }
        }}
        onClick={(e) => this.getFeatureInfo(e)}
      >
        <LayersControl position="topleft">
          <BaseLayer checked name="Light">
            <TileLayer
              url={`https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_nolabels/{z}/{x}/{y}${
                Browser.retina ? "@2x.png" : ".png"
              }`}
            />
          </BaseLayer>
          <BaseLayer name="Street">
            <TileLayer
              url={`https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager_labels_under/{z}/{x}/{y}'${
                Browser.retina ? "@2x.png" : ".png"
              }`}
            />
          </BaseLayer>
          <BaseLayer name="Satellite">
            <TileLayer
              url={`https://api.tiles.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}${
                Browser.retina ? "@2x.png" : ".png"
              }?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw`}
            />
          </BaseLayer>
          <LayersControl.Overlay name={"GeoServer"} checked>
            <WMSTileLayer
              ref="geoserver"
              tileSize={256}
              format={"image/png"}
              keepBuffer={120}
              transparent={true}
              layers={layers}
              url={this.state.geoUrl}
            />
          </LayersControl.Overlay>
        </LayersControl>
        {this.props.regiaoPerms !== null && (
          <GeoJSON
            key={"regiaperms"}
            data={this.props.regiaoPerms}
            style={{
              fillColor: "gray",
              fillOpacity: 0.7,
              color: "gray",
              weight: 3,
              opacity: 1,
            }}
          />
        )}
        <div
          hidden={this.props.hideLegend}
          className="x-container legenda x-abs-layout-item x-container-default"
          role="presentation"
          id="container-1070"
          style={{ width: "180px" }}
        >
          <section className="map-dynamic-legend">
            <span className="title">
              <b>Legenda</b>
            </span>
            <section className="colors">
              <div className="legend">
                <div className="circle cn" />
                <span className="text">Construção nova</span>
              </div>
              <div className="legend">
                <div className="circle oe" />
                <span className="text">Obra em edificado</span>
              </div>
              <div className="legend">
                <div className="circle oer" />
                <span className="text">Obra escassa relevância</span>
              </div>
              <div className="legend">
                <div className="square perms" />
                <span className="text">Área não subscrita</span>
              </div>
            </section>
            <section className="legend-icons icons">
              <i className="fa fa-home" title="Moradia" aria-hidden="true" />
              <i
                className="fa fa-building"
                title="Edifício de Apartamentos"
                aria-hidden="true"
              />
              <i className="fa fa-globe" title="Turismo" aria-hidden="true" />
              <i
                className="fa fa-briefcase"
                title="Escritórios"
                aria-hidden="true"
              />
              <i
                className="fa fa-shopping-cart"
                title="Retalho"
                aria-hidden="true"
              />
              <i
                className="fa fa-industry"
                title="Indústria"
                aria-hidden="true"
              />
              <i
                className="fa fa-users"
                title="Equipamentos sociais"
                aria-hidden="true"
              />
              <i className="fa fa-star" title="Outros" aria-hidden="true" />
            </section>
          </section>
        </div>
        <div className="mapFilterZoom">
          <label className="switch">
            <input
              type="checkbox"
              onClick={(e) => {
                this.setState({ filterMapActive: e.target.checked });
                if (!e.target.checked) {
                  this.clearMapFilter();
                } else {
                  this.mapFilter();
                }
                // this.filterByMapZoom(e)
              }}
            />
            <span className="slider round" />
          </label>
          {"  Filtrar com zoom do mapa"}
        </div>
      </Map>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    regiaoPic: state.pics.settings.bbox_selected,
    concelhoLic: state.licencas.settings.bbox_selected,
    regCon: state.empreendimentos.settings.bbox_selected,
    map: state.map.map,
    regiaoPerms: state.auth.regiao,
    concelhoPerms: state.auth.concelho,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setZomm: (zoomObject) => dispatch(setZomm(zoomObject)),
    setMapRef: (ref) => dispatch(setMapRef(ref)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MapComponent));
