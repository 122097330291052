import React, { useRef } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import Button from "@material-ui/core/Button";
import { setChartActive, getDataChart } from "../../redux/actions/pics";
import {
  setChartActive as setChartActiveLic,
  getDataChart as getDataChartLic,
} from "../../redux/actions/licencas";
import { useDispatch, useSelector } from "react-redux";

const chartType = {
  AREA: "area_construcao",
  "Nº FOGOS": "n_fogos_total",
};

const Charts = ({ tipoChar, dataChart, active, stateSelector }) => {
  const dispatch = useDispatch();
  const { params } = useSelector((state) =>
    stateSelector === "pics" ? state.pics : state.licencas
  );

  /**
   * Change variable count in chart active
   */
  const handlerUpdateCharVar = (value) => {
    if (stateSelector === "pics") {
      dispatch(setChartActive(tipoChar, value));
      dispatch(getDataChart(chartType[tipoChar], value, params));
    } else {
      dispatch(setChartActiveLic(tipoChar, value));
      dispatch(getDataChartLic(chartType[tipoChar], value, params));
    }
  };

  return (
    <div ref={useRef("chart_" + tipoChar)}>
      <div className="chartActions">
        <div className={"chartType"}>{tipoChar}</div>
        <div className={"actionsBtn"} hidden={tipoChar === "Nº TOTAL POR ANO"}>
          <Button
            onClick={() => handlerUpdateCharVar("destino")}
            className={active === "destino" ? "active" : ""}
          >
            Destino
          </Button>{" "}
          |
          <Button
            hidden={stateSelector === "licencas"}
            onClick={() => handlerUpdateCharVar("classe_energetica")}
            className={active === "classe_energetica" ? "active" : ""}
          >
            CE
          </Button>{" "}
          {stateSelector === "pics" ? "|" : ""}
          <Button
            onClick={() => handlerUpdateCharVar("tipo")}
            className={active === "tipo" ? "active" : ""}
          >
            Tipo Obra
          </Button>
        </div>
      </div>
      <HighchartsReact highcharts={Highcharts} options={dataChart} />
    </div>
  );
};

export default Charts;
