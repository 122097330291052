import React from "react";
import Table from "react-bootstrap/Table";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AGENTES = {
  cae: "CAE",
  cp: "Código-Postal",
  email: "Email",
  morada: "Endereço",
  nif: "NIF",
  nome: "Designação",
  telefones: "Telefones",
  tipo: "Proprietario",
  website: "Website",
};

const ARQUITETOS = {
  tipo: "Arquiteto",
  cp: "Código-Postal",
  email: "Email",
  morada: "Endereço",
  nif: "NIF",
  nome: "Designação",
  telefones: "Telefones",
  atelier: "Atelier",
  website: "Website",
  numero_ordem: "Número da Ordem",
};

const Entidad = ({ itemDetail }) => {

  const checkIfUrl = (value) => {
    try {
      new URL(value);
    } catch (_) {
      return false;
    }
    return true;
  };

  return (
    <div className="agentes">
      {itemDetail.agentes
        .filter((agente) => agente.tipo_id !== 3)
        .map((agente, key) => (
          <div className="agente" key={key}>
            <h3 className="tableTitle">{agente.tipo}</h3>
            <Table striped bordered hover>
              <tbody>
                {Object.keys(agente).map((keyName, index) => {
                  console.log(keyName, index);
                  if (
                    keyName !== "tipo" &&
                    keyName !== "tipo_id" &&
                    keyName !== "tipoid" &&
                    keyName !== "logo" &&
                    agente[keyName]
                  ) {
                    return (
                      <tr
                        key={index}
                        onClick={() => {
                          if (checkIfUrl(agente[keyName])) {
                            const win = window.open(agente[keyName], "_blank");
                            win.focus();
                          }
                        }}
                      >
                        <td className="tableLeft">{AGENTES[keyName]}:</td>
                        <td
                          className={
                            checkIfUrl(agente[keyName])
                              ? "tableRight link"
                              : "tableRight"
                          }
                        >
                          {agente[keyName]}{" "}
                          {checkIfUrl(agente[keyName]) ? (
                            <FontAwesomeIcon icon={faExternalLinkAlt} />
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    );
                  }
                  return null;
                })}
              </tbody>
            </Table>
          </div>
        ))}

      {itemDetail.arquitetos &&
        itemDetail.arquitetos.map((arquiteto, key) => (
          <div className="agente" key={key}>
            <h3 className="tableTitle">{ARQUITETOS.tipo}</h3>
            <Table striped bordered hover>
              <tbody>
                {Object.keys(arquiteto).map((keyName, index) => {
                  console.log(keyName, index);
                  if (
                    keyName !== "id" &&
                    arquiteto[keyName]
                  ) {
                    return (
                      <tr
                        key={index}
                        onClick={() => {
                          if (checkIfUrl(arquiteto[keyName])) {
                            const win = window.open(
                              arquiteto[keyName],
                              "_blank"
                            );
                            win.focus();
                          }
                        }}
                      >
                        <td className="tableLeft">{ARQUITETOS[keyName]}:</td>
                        <td
                          className={
                            checkIfUrl(arquiteto[keyName])
                              ? "tableRight link"
                              : "tableRight"
                          }
                        >
                          {arquiteto[keyName]}{" "}
                          {checkIfUrl(arquiteto[keyName]) ? (
                            <FontAwesomeIcon icon={faExternalLinkAlt} />
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    );
                  }
                  return null;
                })}
              </tbody>
            </Table>
          </div>
        ))}
    </div>
  );
};

export default Entidad;
