import * as types from "../constants";

/**
 * authInfo interface
 */
export const authInfo = {
  user: {},
  concelho: null,
  regiao: null,
};

/**
 * Receives state and action with function type and payload
 */
export const authReducer = (state = authInfo, action) => {
  switch (action.type) {
    case types.AUTH_USER_INFO:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case types.REGIAO_PERMS:
      return {
        ...state,
        regiao: {
          ...action.payload,
        },
      };
    case types.CONCELHO_PERMS:
      return {
        ...state,
        concelho: {
          ...action.payload,
        },
      };
    default:
      return {
        ...state,
      };
  }
};
