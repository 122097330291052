import * as types from "../constants";
import API from "../configs/api";

/**
 * Fetch and filter licencas | edificios em obra
 * Fetch filters to
 */
export const fetchData = (params) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_LOADING_STATUS,
      payload: true,
    });
    const response = await API.get("licenca/", { params: params });
    setTimeout(async () => {
      dispatch({
        type: types.GET_LICENCAS,
        payload: response.data,
      });
      /**
       * Fetch fiter Tipo with params
       */
      var tipoConfigs = { ...params };
      delete tipoConfigs["tipo"];
      const responseTipo = await API.get(`licenca/filter/tipo/`, {
        params: tipoConfigs,
      });
      dispatch({
        type: types.GET_TIPO_LICENCAS,
        payload: responseTipo.data.results,
      });
      /**
       * Fetch fiter Destino with params
       */
      var destinoConfigs = { ...params };
      delete destinoConfigs["destino"];
      const responseDestino = await API.get(`licenca/filter/destino/`, {
        params: destinoConfigs,
      });
      dispatch({
        type: types.GET_DESTINO_LICENCAS,
        payload: responseDestino.data.results,
      });
      dispatch({
        type: types.SET_LOADING_STATUS,
        payload: false,
      });
    }, 1000);
  };
};

/**
 * Fetch more licencas | edificios em obra
 * Passing next url
 */
export const fetchMoreData = (next) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_LOADING_STATUS,
      payload: true,
    });
    const response = await API.get("", { baseURL: next });
    setTimeout(() => {
      dispatch({
        type: types.GET_MORE_LICENCAS,
        payload: response.data,
      });
      dispatch({
        type: types.SET_LOADING_STATUS,
        payload: false,
      });
    }, 1000);
  };
};

/**
 * Fetch filter tipo for licencas | edificios em obra
 */
export const fetchTipoLicencas = () => {
  return async (dispatch) => {
    const response = await API.get(`licenca/filter/tipo/`);
    dispatch({
      type: types.GET_TIPO_LICENCAS,
      payload: response.data.results,
    });
  };
};

/**
 * Fetch filter destino for licencas | edificios em obra
 */
export const fetchDestinoLicencas = () => {
  return async (dispatch) => {
    const response = await API.get(`licenca/filter/destino/`);
    dispatch({
      type: types.GET_DESTINO_LICENCAS,
      payload: response.data.results,
    });
  };
};

/**
 * Fetch filter concelhos for licencas | edificios em obra
 * and format to be compatible with select component
 */
export const fetchConcelhoLicencas = () => {
  return async (dispatch) => {
    const response = await API.get(`licenca/concelho/`);
    let list = [];
    for (let x of response.data.results) {
      list.push({ value: x.id, label: x.name, bbox: x.bbox });
    }
    dispatch({
      type: types.GET_CONCELHOS_LICENCAS,
      payload: list,
    });
  };
};

/**
 * Filter licencas | edificios em obra
 */
export const filterLicencas = (params) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_LOADING_STATUS,
      payload: true,
    });
    dispatch({
      type: types.GET_LICENCAS,
      payload: { results: [], count: 0, next: null, previous: null },
    });
    const response = await API.get("licenca/", { params: params });
    setTimeout(() => {
      dispatch({
        type: types.GET_LICENCAS,
        payload: response.data,
      });
    }, 1000);
    /**
     * Fetch filter Tipo with params
     */
    var tipoConfigs = { ...params };
    delete tipoConfigs["tipo"];
    const responseTipo = await API.get(`licenca/filter/tipo/`, {
      params: tipoConfigs,
    });
    dispatch({
      type: types.GET_TIPO_LICENCAS,
      payload: responseTipo.data.results,
    });
    /**
     * Fetch filter Destino with params
     */
    var destinoConfigs = { ...params };
    delete destinoConfigs["destino"];
    const responseDestino = await API.get(`licenca/filter/destino/`, {
      params: destinoConfigs,
    });
    dispatch({
      type: types.GET_DESTINO_LICENCAS,
      payload: responseDestino.data.results,
    });
    dispatch({
      type: types.SET_LOADING_STATUS,
      payload: false,
    });
  };
};

/**
 * Set initial page configs
 */
export const setFilterParamsLicencas = (params) => {
  const emptyParams = {
    concelho: null,
    regiao: null,
    alvara_data_after: null,
    alvara_data_before: null,
    area_construcao_min: "",
    area_construcao_max: "",
    tem_empreiteiro: false,
    tem_arquitecto: false,
    tipo: null,
    destino: null,
  };
  return (dispatch) => {
    dispatch({
      type: types.SET_FILTER_PARAMS_LICENCAS,
      payload: Object.keys(params).length === 0 ? emptyParams : params,
    });
  };
};

/**
 * Fetch search fields for licencas | edificios em obra
 */
export const getSearchFields = () => {
  return async (dispatch) => {
    const response = await API("licenca/search_filters/");
    dispatch({
      type: types.GET_SEARCH_FIELDS_LIC,
      payload: response.data,
    });
  };
};

/**
 * Bookmark licencas | edificios em obra
 */
export const setBookMarkLic = (position, status) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_BOOKMARK_LICENCA,
      payload: position,
      status: status,
    });
  };
};

/**
 * Receive compare values and fetch counts to design chart
 */
export const getDataChart = (values, categories, params) => {
  return async (dispatch) => {
    const response = await API.get(`licenca/chart/${values}/${categories}/`, {
      params: params,
    });
    switch (values) {
      case "n_fogos_total":
        dispatch({
          type: types.SET_FOGOS_LIC_CHART,
          payload: response.data.results,
        });
        break;
      case "area_construcao":
        dispatch({
          type: types.SET_AREA_LIC_CHART,
          payload: response.data.results,
        });
        break;
      default:
        break;
    }
  };
};

/**
 * Activate diferent compare data in a ceretain chart
 */
export const setChartActive = (chart, activeData) => {
  return (dispatch) => {
    switch (chart) {
      case "AREA":
        dispatch({
          type: types.SET_AREA_CHART_LIC_ACTIVE_DATA,
          payload: activeData,
        });
        break;
      case "Nº FOGOS":
        dispatch({
          type: types.SET_FOGOS_CHART_LIC_ACTIVE_DATA,
          payload: activeData,
        });
        break;
      default:
        break;
    }
  };
};

export const setChartViewActive = (status) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_CHARTS_VIEW_LIC_ACTIVE,
      payload: status,
    });
  };
};

/**
 * Set filters active with values to persistence data
 */
export const setActiveFilters = (filter) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_ACTIVE_FILTERS_LICENCAS,
      payload: filter,
    });
  };
};

/**
 * Reset page settings
 */
export const setSettingsLic = (setting) => {
  const emptySettings = {
    chartViewActive: "fichas",
    bbox_selected: null,
    show_alvara_data_after: null,
    show_alvara_data_before: null,
  };
  return (dispatch) => {
    dispatch({
      type: types.SET_LIC_SETTINGS,
      payload: Object.keys(setting).length === 0 ? emptySettings : setting,
    });
  };
};

/**
 * Set search values to filter data
 */
export const setSearchValueLic = (searchvalues) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_FILTER_PARAMS_SEARCH_LIC,
      payload: searchvalues,
    });
  };
};

/**
 * Set bounding box selected in Concelho select
 */
export const setBboxSelectLic = (selected) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_BBOX_SELECTED_LIC,
      payload: selected,
    });
  };
};

/**
 * Fetch especial data chart
 */
export const getDataChartTPY = (params) => {
  return async (dispatch) => {
    const response = await API.get(`licenca/total_per_year/`, {
      params: params,
    });
    dispatch({
      type: types.SET_TPY_LIC_CHART,
      payload: response.data.results,
    });
  };
};
