import React, {} from 'react';
import {Breadcrumb} from "react-bootstrap";
import './BookmarkPage.scss';
import {
    Grid,
    Table,
    TableBandHeader,
    TableHeaderRow
} from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import {useDispatch, useSelector} from "react-redux";
import {getFavorites} from '../../redux/actions/bookmarks';
import {
    columnBandsLic,
    columnBandsPics,
    BookmarkscolumnsLic,
    BookmarkscolumnsEmp,
    BookmarkscolumnsPics
} from "../../configs/configs";
import API from "../../redux/configs/api";


const Bookmarks = () => {
    const [view, setView] = React.useState("pic")
    const data = useSelector(state => state.bookmarks.list)
    const count = useSelector(state => state.bookmarks.count)
    const {user} = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const PARENTS = {
        'pic': "edificios-em-projecto",
        'licenca': 'edificios-em-obra',
        'empreendimento': "edificios-em-comercializacao"
    }
    const COLUMNS = {
        'pic': BookmarkscolumnsPics,
        'licenca': BookmarkscolumnsLic,
        'empreendimento': BookmarkscolumnsEmp
    }
    const COLUMNBANDS = {
        'pic': columnBandsPics,
        'licenca': columnBandsLic,
        'empreendimento': []
    }

    const handlerViewChange = (viewChange) => {
        setView(viewChange)
        dispatch(getFavorites(viewChange))
    }

    React.useEffect(() => {
        dispatch(getFavorites(view))
    }, [dispatch, view])

    const TableRow = ({row, ...restProps}) => (
        <Table.Row
            {...restProps}
            // eslint-disable-next-line no-alert
            onClick={async (e) => {
                if (e.target.classList.contains('bookmark') || e.target.parentElement.classList.contains('bookmark')) {
                    const bookmark = await API.delete(`bookmarks/${view}/add-remove/${row.id}/`);
                    if (bookmark.status === 204) {
                        dispatch(getFavorites(view))
                    }
                }
                if (e.target.classList.contains('openUrl') || e.target.parentElement.classList.contains('openUrl')) {
                    const win = window.open(`/${PARENTS[view]}/${row.id}`, "_blank");
                    win.focus();
                }
            }}
            onDoubleClick={() => {
                const win = window.open(`/${PARENTS[view]}/${row.id}`, "_blank");
                win.focus();
            }}
            style={{
                cursor: 'pointer',
            }}
        />
    );

    return (
        <section className='bookmarks'>

            <Breadcrumb>
                <Breadcrumb.Item active={view === "pic"}
                                 href="#"
                                 onClick={() => handlerViewChange("pic")}>edifícios em projecto
                </Breadcrumb.Item>
                <Breadcrumb.Item active={view === 'licenca'}
                                 href="#"
                                 onClick={() => handlerViewChange('licenca')}>edifícios em obra
                </Breadcrumb.Item>
                {user.admin && (
                    <Breadcrumb.Item active={view === "empreendimento"} href="#"
                                     onClick={() => handlerViewChange("empreendimento")}>Edificios em comercialização
                    </Breadcrumb.Item>
                )}
                | {"  "}{count} {"  "}{"  "} resultados encontrados
            </Breadcrumb>
            <div className="listBookmarksWrapper">
                {data.length > 0 &&
                    <div className="card">
                        <Grid
                            rows={data}
                            columns={COLUMNS[view]}
                        >
                            <Table
                                rowComponent={TableRow}/>
                            <TableHeaderRow/>
                            <TableBandHeader
                                columnBands={COLUMNBANDS[view]}
                            />
                        </Grid>
                    </div>
                }
            </div>
        </section>
    );
}

export default Bookmarks;
