import React, {useRef, useState} from "react";
import PropTypes from "prop-types";
import {
    Button,
    Col,
    Form,
    Overlay,
    Popover,
} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faChevronDown, faSearch, faChevronUp} from "@fortawesome/free-solid-svg-icons";

function Toolbar(props) {
    const inputEl = useRef(null);

    const [state, setState] = useState({
        show: false,
        target: null,
    });

    const handlerMoreSearchFields = (e) => {
        setState({
            ...state,
            show: !state.show,
            target: e.target,
        });
    };

    return (
        <div className="topBarWrapper">
            <div className="topBarActions">
                <div className="action-rigth">
                    <Button onClick={() => props.exportData()} className={"btn_export"}>
                        <FontAwesomeIcon icon={faDownload}/>
                    </Button>
                    <div id="search" ref={inputEl}>
                        <FontAwesomeIcon
                            className="moreSearch"
                            onClick={(e) => handlerMoreSearchFields(e)}
                            icon={state.show ? faChevronUp : faChevronDown}/>
                        <input
                            aria-label="Search"
                            autoComplete="off"
                            id="search-input"
                            name="search"
                            value={props.searchFilters.search}
                            onKeyUp={(e) => {
                                if (e.key === "Enter") {
                                    props.filterBySearchFields();
                                }
                            }}
                            onChange={(e) => {
                                props.setSearchValue({
                                    [e.target.name]: e.target.value,
                                });
                                if (e.target.value === "") {
                                    props.clearShearField();
                                }
                            }}
                            placeholder="Pesquisa"
                            type="search"
                        />
                        <FontAwesomeIcon icon={faSearch} onClick={() => props.filterBySearchFields()}/>
                        <Overlay
                            show={state.show}
                            target={inputEl}
                            placement="bottom"
                            containerPadding={20}
                        >
                            <Popover id="popover-contained searchfields">
                                <Popover.Content>
                                    {props.search_filters.length > 0 &&
                                        props.search_filters.map((field, key) => (
                                            <Form.Row key={key}>
                                                <Form.Label className="field-title" column="sm" lg={5}>
                                                    {field.title}
                                                </Form.Label>
                                                <Col>
                                                    <Form.Control
                                                        value={props.searchFilters[field.name]}
                                                        size="sm"
                                                        type="text"
                                                        name={field.name}
                                                        autoComplete="off"
                                                        onChange={(e) =>
                                                            props.setSearchValue({
                                                                [e.target.name]: e.target.value,
                                                            })
                                                        }
                                                    />
                                                </Col>
                                            </Form.Row>
                                        ))}
                                    <Form.Row>
                                        <Form.Label className="field-title" column="sm" lg={5}>
                                            Palavra-Chave
                                        </Form.Label>
                                        <Col>
                                            <Form.Control
                                                value={props.searchFilters.search}
                                                size="sm"
                                                type="text"
                                                name="search"
                                                autoComplete="off"
                                                onChange={(e) =>
                                                    props.setSearchValue({
                                                        [e.target.name]: e.target.value,
                                                    })
                                                }
                                            />
                                        </Col>
                                    </Form.Row>
                                    <Button
                                        className={"btn-danger"}
                                        size={"sm"}
                                        onClick={(e) => handlerMoreSearchFields(e)}
                                    >
                                        Fechar
                                    </Button>
                                    <Button
                                        className={"btn-search-actions"}
                                        size={"sm"}
                                        onClick={() => props.clearShearField()}
                                    >
                                        Limpar
                                    </Button>
                                    <Button
                                        className={"btn-search-actions"}
                                        size={"sm"}
                                        onClick={() => props.filterBySearchFields()}
                                    >
                                        Pesquisar
                                    </Button>
                                </Popover.Content>
                            </Popover>
                        </Overlay>
                    </div>
                </div>
            </div>
        </div>
    );
}

Toolbar.propTypes = {
    loading: PropTypes.bool,
    count: PropTypes.number,
    mainView: PropTypes.string,
    setState: PropTypes.func,
    exportData: PropTypes.func,
    search_filters: PropTypes.array,
    searchFilters: PropTypes.object,
    setSearchValue: PropTypes.func,
    filterBySearchFields: PropTypes.func,
    handlerMoreSearchFields: PropTypes.func,
    clearShearField: PropTypes.func,
};

export default Toolbar;
