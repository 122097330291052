import API from "../configs/api";
import * as types from "../constants";

export const getEmpreendimentos = (next = null) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_LOADING_STATUS,
      payload: true,
    });
    let configs = {},
      url = "empreendimento/";
    if (next !== null) {
      configs["baseURL"] = next;
      url = "";
    }
    let res = await API.get(url, configs);
    dispatch({
      type:
        next === null
          ? types.GET_EMPREENDIMENTOS
          : types.GET_MORE_EMPREENDIMENTOS,
      payload: res.data,
    });
    dispatch({
      type: types.SET_LOADING_STATUS,
      payload: false,
    });
  };
};

export const filterEmpreendimentos = (params) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_LOADING_STATUS,
      payload: true,
    });
    const response = await API.get("empreendimento/", { params: params });
    dispatch({
      type: types.GET_EMPREENDIMENTOS,
      payload: response.data,
    });
    dispatch({
      type: types.SET_LOADING_STATUS,
      payload: false,
    });
  };
};

export const getSearchFields = () => {
  return async (dispatch) => {
    const response = await API("empreendimento/search_filters/");
    dispatch({
      type: types.GET_SEARCH_FIELDS_EMP,
      payload: response.data,
    });
  };
};

export const setBookMarkEmp = (position, status) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_BOOKMARK_EMPREENDIMENTOS,
      payload: position,
      status: status,
    });
  };
};

export const setFilterParams = (params) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_PARAMS_EMP,
      payload: params,
    });
  };
};

export const setBboxSelect = (selected) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_BBOX_SELECTED_EMP,
      payload: selected,
    });
  };
};

export const loadOptions = (inputValue) => {
  if (inputValue.length > 2) {
    return new Promise((resolve, reject) => {
      API.get(`empreendimento/regiao-concelho/`, {
        params: { search: inputValue },
      })
        .then((response) => {
          let list = [];
          for (let x of response.data) {
            list.push({
              value: x.id,
              label: x.name,
              bbox: x.bbox,
              origin: x.origin,
            });
          }
          resolve(list);
        })
        .catch(reject);
    });
  }
};

export const setSettings = (setting) => {
  const emptySetting = {
    bbox_selected: null,
    disponibilidade: false,
  };
  return (dispatch) => {
    dispatch({
      type: types.SET_EMP_SETTINGS,
      payload: Object.keys(setting).length === 0 ? emptySetting : setting,
    });
  };
};

export const setSearchValue = (searchvalues) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_FILTER_PARAMS_SEARCH_EMP,
      payload: searchvalues,
    });
  };
};
