import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
// ICONS
import {
  BuildingIcon,
  HouseIcon,
  MarketIcon,
  WorkIcon,
  WorldIcon,
} from "../icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
// COMPONENTS
import Dados from "../components/Detail/Dados";
import Entidad from "../components/Detail/Entidad";
import Point from "../components/Detail/Point";
// REDUX
import API from "../redux/configs/api";
import SlideShowComponent from "../components/SlideShow/SlideShowComponent";
import Apresentacao from "../components/Detail/Apresentacao";
import Fracoes from "../components/Detail/Fracoes";

const Detail = ({ detailType }) => {
  const { id } = useParams();
  const [btnActive, setBtnActive] = React.useState(
    detailType === "empreendimento" ? 10 : 1
  );
  const [itemDetail, setItemDetail] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const [disclaimer, setDisclaimer] = React.useState(false);

  const getItemDetail = useCallback(() => {
    API.get(`${detailType}/${id}/`).then((response) => {
      setItemDetail(response.data);
    });
  }, [detailType, id]);

  React.useEffect(() => {
    getItemDetail();
  }, [getItemDetail]);

  const handlerBookMark = async (itemId, del = false) => {
    let bookmark;
    if (del) {
      bookmark = await API.delete(
        `bookmarks/${detailType}/add-remove/${itemId}/`
      );
    } else {
      bookmark = await API.post(`bookmarks/${detailType}/add-remove/`, {
        [detailType]: itemId,
      });
    }
    if (bookmark.status === 201 || bookmark.status === 204) {
      getItemDetail();
    }
  };

  const setDestinoIcon = (destino_id) => {
    switch (destino_id) {
      case 1:
        return <HouseIcon />;
      case 2:
        return <BuildingIcon />;
      case 3:
        return <WorldIcon />;
      case 4:
        return <WorkIcon />;
      case 5:
        return <MarketIcon />;
      default:
        break;
    }
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const showSlideShow = () => {
    if (itemDetail.fotos.length > 1) {
      setShowModal(true);
    }
  };

  const checkEmpreendimentoEntidades = (itemDetail) => {
    return (
      itemDetail.agentes.filter((agente) => agente.tipo_id !== 3).length ===
        0 && itemDetail.arquitetos?.length === 0
    );
  };

  return itemDetail !== null && detailType !== "empreendimento" ? (
    <div className="Detail">
      <div className="sideBarLeft">
        {itemDetail.interno.classe_energetica_id !== null ? (
          <div
            className={`classe_energetica classe_energetica_${itemDetail.interno.classe_energetica_id}`}
          />
        ) : (
          ""
        )}

        <div className="refci">
          {setDestinoIcon(itemDetail.interno.destino_id)}
          {itemDetail.id}
        </div>
        <div className="image">
          <img className={"imagem"} src={itemDetail.fotos[0]} alt="Edificio" />
        </div>

        <button
          onClick={() => setBtnActive(1)}
          className={btnActive === 1 ? "btn btn-block active" : "btn btn-block"}
        >
          dados gerais
        </button>
        <button
          hidden={itemDetail.agentes.length === 0}
          onClick={() => setBtnActive(2)}
          className={btnActive === 2 ? "btn btn-block active" : "btn btn-block"}
        >
          entidades
        </button>
        <button
          onClick={() => setBtnActive(3)}
          className={btnActive === 3 ? "btn btn-block active" : "btn btn-block"}
        >
          mapa
        </button>
        <button
          hidden={itemDetail.relacionados.empreendimento === null}
          onClick={() => {
            const win = window.open(
              `/edificios-em-comercializacao/${itemDetail.relacionados.empreendimento}`,
              "_blank"
            );
            win.focus();
          }}
          className={btnActive === 4 ? "btn btn-block active" : "btn btn-block"}
        >
          {"edificios em comercialização  "}{" "}
          <FontAwesomeIcon icon={faExternalLinkAlt} />
        </button>
        <button
          hidden={
            itemDetail.relacionados.licenca === null ||
            itemDetail.relacionados.licenca === undefined
          }
          onClick={() => {
            const win = window.open(
              `/edificios-em-obra/${itemDetail.relacionados.licenca}`,
              "_blank"
            );
            win.focus();
          }}
          className={btnActive === 4 ? "btn btn-block active" : "btn btn-block"}
        >
          {"edificios em obra  "} <FontAwesomeIcon icon={faExternalLinkAlt} />
        </button>
        <button
          onClick={() => window.close()}
          // onClick={() => history.push(`/edificios-em-projecto`)}
          className="btn btn-block"
        >
          Voltar
        </button>
        <div className="btn-bookmark">
          <button
            onClick={() =>
              handlerBookMark(itemDetail.id, itemDetail.interno.bookmarked)
            }
            className="btn btn-block"
          >
            {itemDetail.interno.bookmarked
              ? "Remover dos meus edifícios"
              : "Adicionar aos meus edifícios"}
          </button>
        </div>
      </div>
      <div className={"detailContent"}>
        {btnActive === 1 && <Dados {...itemDetail} />}
        {btnActive === 2 && <Entidad itemDetail={itemDetail} />}
        {btnActive === 3 && (
          <Point
            point={[
              itemDetail.localizacao.gps.coordinates[1],
              itemDetail.localizacao.gps.coordinates[0],
            ]}
          />
        )}
      </div>
    </div>
  ) : (
    itemDetail !== null && (
      <div className="Detail">
        <div className="sideBarLeft">
          <div className="refci">{itemDetail.dados_gerais.nome}</div>
          <div className="image">
            <img
              onClick={() => showSlideShow()}
              className={
                itemDetail.fotos.length > 1 ? "imagem multi" : "imagem"
              }
              src={itemDetail.fotos[0]}
              alt="Edificio"
            />
            <SlideShowComponent
              images={itemDetail.fotos}
              modalShow={showModal}
              hideModal={() => hideModal()}
            />
          </div>
          <button
            onClick={() => setBtnActive(10)}
            className={
              btnActive === 10 ? "btn btn-block active" : "btn btn-block"
            }
          >
            apresentação
          </button>
          <button
            hidden={checkEmpreendimentoEntidades(itemDetail)}
            onClick={() => setBtnActive(40)}
            className={
              btnActive === 40 ? "btn btn-block active" : "btn btn-block"
            }
          >
            entidades
          </button>
          <button
            hidden={!itemDetail.localizacao.gps}
            onClick={() => setBtnActive(20)}
            className={
              btnActive === 20 ? "btn btn-block active" : "btn btn-block"
            }
          >
            mapa
          </button>
          <button
            onClick={() => setBtnActive(30)}
            className={
              btnActive === 30 ? "btn btn-block active" : "btn btn-block"
            }
          >
            detalhe frações
          </button>
          {itemDetail.relacionados.licenca !== null && (
            <button
              onClick={() => {
                const win = window.open(
                  `/edificios-em-obra/${itemDetail.relacionados.licenca}`,
                  "_blank"
                );
                win.focus();
              }}
              className={
                btnActive === 4 ? "btn btn-block active" : "btn btn-block"
              }
            >
              {"Licença de construção  "}{" "}
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </button>
          )}
          {itemDetail.relacionados.pic !== null && (
            <button
              onClick={() => {
                const win = window.open(
                  `/edificios-em-projecto/${itemDetail.relacionados.pic}`,
                  "_blank"
                );
                win.focus();
              }}
              className={
                btnActive === 5 ? "btn btn-block active" : "btn btn-block"
              }
            >
              {"pré - certificado energético  "}{" "}
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </button>
          )}
          <button
            //onClick={() => setBtnActive(60)}
            onClick={() =>
              window.open(
                "https://www.confidencialimobiliario.com/pedido-de-informacao/",
                "_blank"
              )
            }
            className={
              btnActive === 60 ? "btn btn-block active" : "btn btn-block"
            }
          >
            editar informação edifício
          </button>

          <button
            onClick={() => window.close()}
            // onClick={() => history.push(`/edificios-em-comercializacao`)}
            className="btn btn-block"
          >
            Voltar
          </button>
          <div className="btn-bookmark">
            <button
              onClick={() =>
                handlerBookMark(itemDetail.id, itemDetail.interno.bookmarked)
              }
              className="btn btn-block"
            >
              {itemDetail.interno.bookmarked
                ? "Remover dos meus edifícios"
                : "Adicionar aos meus edifícios"}
            </button>
          </div>
        </div>
        <div className={"empreendimento"}>
          {btnActive === 10 && <Apresentacao detail={itemDetail} />}
          {btnActive === 20 && (
            <Point
              point={[
                itemDetail.localizacao.gps.coordinates[1],
                itemDetail.localizacao.gps.coordinates[0],
              ]}
            />
          )}
          {btnActive === 30 && <Fracoes fracao_values={itemDetail.detalhes} />}
          {btnActive === 40 && <Entidad itemDetail={itemDetail} />}

          <div
            className={disclaimer ? "disclaimer open" : "disclaimer"}
            id="disclaimer"
          >
            <h5
              className="openButon"
              onClick={() => setDisclaimer(!disclaimer)}
            >
              DISCLAIMER
            </h5>
            <h5 onClick={() => setDisclaimer(!disclaimer)} className="title">
              DISCLAIMER <span>{">>"}</span>
            </h5>
            <div
              className="disclaimer-content"
              dangerouslySetInnerHTML={{
                __html: itemDetail.interno.disclaimer,
              }}
            />
          </div>
        </div>
      </div>
    )
  );
};

export default Detail;
