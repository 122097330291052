import axios from "axios";
import Cookies from "universal-cookie";

const queryString = require("query-string");
const cookies = new Cookies();
let BASE_URL = "";

/**
 * Check if env production and set BASE_URL
 */
if (process.env.NODE_ENV === "development") {
  BASE_URL = "https://pipelineimobiliario.pt/api/v1/";
} else {
  BASE_URL = "/api/v1/";
}

/**
 * Configuring axios for fetch API
 */
export default axios.create({
  baseURL: `${BASE_URL}`,
  params: {},
  paramsSerializer: (params) => {
    let finalParams = { ...params };
    for (const key of Object.keys(finalParams)) {
      if (
        finalParams[key] === "" ||
        finalParams[key] === null ||
        finalParams[key] === undefined
      ) {
        delete finalParams[key];
      }
      delete finalParams["origin"];
    }
    return queryString.stringify(finalParams);
  },
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
    "X-CSRFToken": cookies.get("csrftoken"),
    Authorization:
      process.env.NODE_ENV === "development"
        ? "Basic cGJhcmJvc2FAZXZvbHV0aW8ucHQ6aWtrSTljeUtwUyYwNTg="
        : "",
  },
});

/**
 * Create axios connection to export excel data
 */
export const ExportApi = axios.create({
  baseURL: `${BASE_URL}`,
  params: {},
  responseType: "blob",
  paramsSerializer: (params) => {
    for (const key of Object.keys(params)) {
      if (
        params[key] === "" ||
        params[key] === null ||
        params[key] === undefined
      ) {
        delete params[key];
      }
    }
    return queryString.stringify(params);
  },
  headers: {
    Accept: "application/vnd.ms-excel, text/plain, */*",
    Authorization:
      process.env.NODE_ENV === "development" ? process.env.REACT_APP_AUTH : "",
  },
});
