import * as types from "../constants";

/**
 * mapInit interface
 */
export const mapInit = {
    map: {
        pic: null, lic: null, emp: null, mapPicRef: null, mapLicRef: null
    }
};

/**
 * Receives state and action with function type and payload 
 */
export const mapReducer = (state = mapInit, action) => {
    switch (action.type) {
        case types.SET_MAP_ZOOM:
            return {
                ...state, map: {
                    ...state.map, ...action.payload
                }
            };
        case types.SET_MAP_REF:
            return {
                ...state, map: {
                    ...state.map, ...action.payload
                }
            };
        default:
            return {
                ...state,
            };
    }
};
