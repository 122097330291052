import React, {Component, useEffect} from "react";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
// COMPONENTS
import HomePage from "./pages/home/HomePage";
import NavBar from "./components/NavBar/NavBar";
import BookmarksPage from "./pages/bookmarks/BookmarkPage";
import {getConcelhoPerms, getInfoUser, getRegiaoPerms} from "./redux/actions/auth";
import PicContainer from "./containers/PicContainer";
import LicencaContainer from "./containers/LicencaContainer";
import ComercialicacaoContainer from "./containers/ComercialicacaoContainer";
import Detail from "./containers/Detail";
// STYLES
import "react-splitter-layout/lib/index.css";
import "react-datepicker/dist/react-datepicker.css";
import "./index.scss";
import {Redirect} from "react-router";

/**
 * Component return Route if authenticated
 */
class ProtectedRoute extends Component {
    render() {
        const {component: Component, user, ...props} = this.props
        return (
            <Route
                {...props}
                render={props => (
                    user.authenticated ?
                        <Component {...props} /> :
                        <Redirect to='/'/>
                )}
            />
        )
    }
}

const App = () => {
    const [modalShow, setModalShow] = React.useState(false);
    const {loading} = useSelector((state) => state.process);
    const dispatch = useDispatch();
    const {user} = useSelector((state) => state.auth);
    /**
     * Dispatch Redux functions to fetch data 
     */
    useEffect(() => {
        dispatch(getInfoUser());
        dispatch(getRegiaoPerms());
        dispatch(getConcelhoPerms());
    }, [dispatch]);
    /**
     * Activate or deativate loading progress
     */
    useEffect(() => {
        if (loading) {
            setModalShow(true);
        } else {
            setModalShow(false);
        }
    }, [loading]);

    return user.authenticated !== undefined && (
        <Router>
            {modalShow && <LinearProgress className="progressBar"/>}
            <NavBar/>
            <Switch>
                <Route exact path="/" component={HomePage}/>
                <ProtectedRoute user={user} exact path="/edificios-em-obra" component={LicencaContainer}/>
                <ProtectedRoute user={user}
                                exact
                                path="/edificios-em-obra/:id"
                                component={() => <Detail detailType={"licenca"}/>}
                />
                <ProtectedRoute user={user} exact path="/edificios-em-projecto" component={PicContainer}/>
                <ProtectedRoute user={user}
                                exact
                                path="/edificios-em-projecto/:id"
                                component={() => <Detail detailType={"pic"}/>}
                />
                <ProtectedRoute user={user}
                                exact
                                path="/edificios-em-comercializacao"
                                component={ComercialicacaoContainer}
                />
                <ProtectedRoute user={user}
                                exact
                                path="/edificios-em-comercializacao/:id"
                                component={() => <Detail detailType={"empreendimento"}/>}
                />
                <ProtectedRoute user={user} exact path="/os-meus-edificios" component={BookmarksPage}/>
            </Switch>
        </Router>
    );
};

export default App;
