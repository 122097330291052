export const columnsPics = [
    { name: "id", title: "RefªCi" },
    { name: "ano_mes", title: "Ano/Mês" },
    { name: "concelho", title: "Concelho" },
    { name: "freguesia", title: "Freguesia" },
    { name: "destino", title: "Nome" },
    { name: "destino_detalhe", title: "Detalhe" },
    { name: "tipo", title: "Tipo de Obra" },
    { name: "area_construcao", title: "Área" },
    { name: "n_fogos_total", title: "Nº Fogos" },
    { name: "classe_energetica", title: "Classe Energética" },
    { name: "promotor", title: "Promotor" },
];

export const BookmarkscolumnsPics = [
    { name: 'bookmarked', title: " " },
    { name: 'openUrl', title: " " },
    { name: "id", title: "RefªCi" },
    { name: "ano_mes", title: "Ano/Mês" },
    { name: "concelho", title: "Concelho" },
    { name: "freguesia", title: "Freguesia" },
    { name: "destino", title: "Nome" },
    { name: "destino_detalhe", title: "Detalhe" },
    { name: "tipo", title: "Tipo de Obra" },
    { name: "area_construcao", title: "Área" },
    { name: "n_fogos_total", title: "Nº Fogos" },
    { name: "classe_energetica", title: "Classe Energética" },
    { name: "promotor", title: "Promotor" },
];

export const columnBandsPics = [
    {
        title: "Destino",
        children: [{ columnName: "destino" }, { columnName: "destino_detalhe" }],
    },
];

export const columnsLic = [
    { name: "id", title: "RefªCi" },
    { name: "alvara", title: "Nº" },
    { name: "alvara_data", title: "Data" },
    { name: "duracao", title: "Duração (Meses)" },
    { name: "concelho", title: "Concelho" },
    { name: "freguesia", title: "Freguesia" },
    { name: "destino", title: "Nome" },
    { name: "destino_detalhe", title: "Detalhe" },
    { name: "tipo", title: "Tipo de Obra" },
    { name: "area_construcao", title: "Area" },
    { name: "n_pisos_acima", title: "Nº Pisos acima" },
    { name: "promotor", title: "Promotor" },
];

export const BookmarkscolumnsLic = [
    { name: 'bookmarked', title: " " },
    { name: 'openUrl', title: " " },
    { name: "id", title: "RefªCi" },
    { name: "alvara", title: "Nº" },
    { name: "alvara_data", title: "Data" },
    { name: "duracao", title: "Duração (Meses)" },
    { name: "concelho", title: "Concelho" },
    { name: "freguesia", title: "Freguesia" },
    { name: "destino", title: "Nome" },
    { name: "destino_detalhe", title: "Detalhe" },
    { name: "tipo", title: "Tipo de Obra" },
    { name: "area_construcao", title: "Area" },
    { name: "n_pisos_acima", title: "Nº Pisos acima" },
    { name: "promotor", title: "Promotor" },
];

export const columnBandsLic = [
    {
        title: "Alvará",
        children: [
            { columnName: "alvara" },
            { columnName: "alvara_data" },
            { columnName: "duracao" },
        ],
    },
    {
        title: "Destino",
        children: [{ columnName: "destino" }, { columnName: "destino_detalhe" }],
    },
];

export const columnsEmp = [
    { name: "id", title: "ID" },
    { name: "nome", title: "Nome" },
    { name: "concelho", title: "Concelho" },
    { name: "abc", title: "ABC" },
    { name: "disponibilidade", title: "Disponibilidade" },
    { name: "nr_fracoes", title: "Nº Frações" },
];

export const BookmarkscolumnsEmp = [
    { name: 'bookmarked', title: " " },
    { name: 'openUrl', title: " " },
    { name: "id", title: "ID" },
    { name: "nome", title: "Nome" },
    { name: "concelho", title: "Concelho" },
    { name: "abc", title: "ABC" },
    { name: "disponibilidade", title: "Disponibilidade" },
    { name: "nr_fracoes", title: "Nº Frações" },
];

export const columnBandsEmp = [
    {
        title: "Alvará",
        children: [
            { columnName: "alvara" },
            { columnName: "alvara_data" },
            { columnName: "duracao" },
        ],
    },
    {
        title: "Destino",
        children: [{ columnName: "destino" }, { columnName: "destino_detalhe" }],
    },
];
