import React from 'react';
import { Form } from 'react-bootstrap';

export const CheckBoxFilter = ({ checked, handlerCheckBox, name, label }) => {
    return (
        <Form.Group controlId="formBasicCheckbox">
            <Form.Check
                checked={checked}
                onChange={(e) => handlerCheckBox(e)}
                name={name}
                type="checkbox"
                label={label}
            />
        </Form.Group>
    )
}