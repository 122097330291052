import * as types from "../constants";

/**
 * initialStatesiteVariables interface
 */
export const initialStatesiteVariables = {
    openFilters: true, menuActive: null
}

/**
 * Receives state and action with function type and payload
 */
export const siteVariablesReducer = (state = initialStatesiteVariables, action) => {
    switch (action.type) {
        case types.SET_MENU_STATE:
            return {
                ...state, openFilters: action.payload
            };
        case types.SET_MENU_ACTIVE:
            return {
                ...state, menuActive: action.payload
            };
        default:
            return {
                ...state,
            };
    }
};
