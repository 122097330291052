import React from "react";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export const AreasFilter = ({
  title,
  filter_min,
  filter_max,
  params,
  handlerValuesFilter,
  cleanText,
}) => {
  return (
    <Form.Group>
      <label>{title}</label>
      {params[filter_min] !== "" && params[filter_min] !== null && (
        <div>
          <FontAwesomeIcon
            onClick={() => {
              handlerValuesFilter(null, true);
            }}
            className="clearDates"
            icon={faTimes}
          />{" "}
          <span className="clean-areas">{cleanText}</span>
        </div>
      )}
      <input
        className="areaMin"
        type="text"
        value={params[filter_min]}
        name={filter_min}
        onChange={(e) => handlerValuesFilter(e)}
      />
      <input
        className="areaMax"
        type="text"
        value={params[filter_max]}
        name={filter_max}
        onChange={(e) => handlerValuesFilter(e)}
      />
    </Form.Group>
  );
};
