import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faThLarge, faList, faChartBar} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";
import './SmallToolbar.scss';

export const SmallToolbar = (props) => {
    return (
        <section className="small-wrapper">

            <div className="small-toolbar">
                <Button onClick={() => props.changeView("list")}
                        className={
                            props.chartViewActive === "list" ? "active btn-icon" : " btn-icon"
                        }>
                    <FontAwesomeIcon icon={faList}/>
                </Button>
                <Button onClick={() => props.changeView("fichas")}
                        className={
                            props.chartViewActive === "fichas" ? "active btn-icon" : " btn-icon"
                        }>
                    <FontAwesomeIcon icon={faThLarge}/>
                </Button>
                <Button onClick={() => {
                    props.changeView("charts");
                    props.updateDataChart();
                }}
                        className={
                            props.chartViewActive === "charts" ? "active btn-icon" : " btn-icon"
                        }>
                    <FontAwesomeIcon icon={faChartBar}/>
                </Button>
                <Button onClick={() => props.exportData()} className=" btn-icon">
                    <FontAwesomeIcon icon={faDownload}/>
                </Button>
            </div>
            <span className='counts'>
                {`${
                    props.count === undefined ? 0 : props.count
                }  RESULTADOS`}
            </span>
        </section>
    )
}
